import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../App.scss";
import { EventState } from "../store/types/event";
import {
  DELETE_EVENT,
  EDIT_EVENT_NAME,
  MAX_NAME_LENGTH,
} from "../utils/constants";
type EventProps = {
  name: string;
  edit: boolean;
  teams: Array<any>;
  index: number;
  selectedEventIndex: number;
  setSelectedEventIndex?: (index: number) => void;
  setEditEvent?: (eventName: string, index: number) => void;
  setDeleteEvent?: (index: number) => void;
};
const Event: React.FC<EventProps> = ({
  name,
  edit,
  teams,
  index,
  selectedEventIndex,
  setSelectedEventIndex = (index: number) => {},
  setEditEvent = (eventName: string, index: number) => {},
  setDeleteEvent = (index: number) => {},
}) => {
  const [editShow, setEditShow] = useState(false);
  const [editEventValue, setEditEventValue] = useState<string>("");
  const [deleteEventValue, setDeleteEventValue] = useState<string>("");
  const [duplicateNameError, setDuplicateNameError] = useState<string>("");
  const [deleteShow, setDeleteShow] = useState(false);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useSelector((state: { event: EventState }) => state.event);

  const saveEditEvent = (name: string, index: number) => {
    name = name.trim();
    setEditEvent(name, index);
    setEditShow(false);
  };

  const checkEventExists = (name: string, ignoreName: string) =>
    !!events.find(
      (event: any) => event.name !== ignoreName && event.name === name
    );

  const validate = (name: string) => {
    name = name.trim();
    const selectedEventName: string = events[selectedEventIndex].name;
    if (checkEventExists(name, selectedEventName)) {
      setDuplicateNameError(name);
    } else {
      setDuplicateNameError("");
    }
  };

  const allowSave = () =>
    !!editEventValue.trim().length && duplicateNameError === "";

  useEffect(() => {
    if (editShow) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [editShow]);

  return (
    <>
      <div
        className={`groups-list-item ${
          index == selectedEventIndex ? "active" : ""
        }`}
        key={index}
        onClick={() => {
          setSelectedEventIndex(index);
        }}
      >
        <span className="name">{name}</span>
        {edit && (
          <span className="ms-auto me-3 d-flex">
            <span
              className="badge badge-secondary badge-pill"
              onClick={() => {
                setEditShow(true);
                setEditEventValue(name);
              }}
            >
              Edit
            </span>
            <span
              className="badge badge-danger badge-pill"
              style={{ marginLeft: 3 }}
              onClick={() => {
                setDeleteShow(true);
                setDeleteEventValue(name);
              }}
            >
              Delete
            </span>
          </span>
        )}

        <span className="text-end members-count">
          {teams.filter((team) => team.selected).length}/{teams.length}
        </span>
      </div>
      <Modal
        show={editShow}
        backdrop="static"
        onHide={() => {
          setEditShow(false);
        }}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>{EDIT_EVENT_NAME}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={editEventValue}
              maxLength={MAX_NAME_LENGTH}
              onKeyUp={(e) => {
                if (e.key === "Enter" && allowSave()) {
                  saveEditEvent(editEventValue, selectedEventIndex);
                }
              }}
              onChange={(e) => {
                setEditEventValue(e.target.value);
                validate(e.target.value);
              }}
              placeholder="Event name"
              ref={inputRef}
            />
          </div>
          {duplicateNameError && (
            <span className="error">{`Event with the name ${duplicateNameError} already exists`}</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setEditShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!allowSave()}
            onClick={() => {
              saveEditEvent(editEventValue, selectedEventIndex);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deleteShow}
        backdrop="static"
        onHide={() => {
          setDeleteShow(false);
        }}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>{DELETE_EVENT}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure to delete event <b>{deleteEventValue}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setDeleteEvent(selectedEventIndex);
              setDeleteShow(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Event;
