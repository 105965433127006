import React, { useEffect, useState } from "react";
import {
  ALL_MEMBERS_DISPLAYED,
  BACKUP_DATA,
  EDIT_EVENTS,
  EDIT_GROUPS,
  EVENTS_MERGED,
  EVENTS_REPLACED,
  EXIT_APPLICATION,
  EXPORT_FILE,
  EXPORT_FILE_LOAD,
  EXPORT_IMPORT_FILE_DEFAULT_NAME,
  EXPORT_IMPORT_FILE_DEFAULT_NAME_EVENTS,
  EXPORT_IMPORT_FILE_DEFAULT_NAME_GROUPS,
  FAQ_LABEL,
  FAQ_URL,
  FILENAME_EMPTY_ERROR,
  FILE_ALREADY_EXISTS,
  FILE_IMPORT_FAILED,
  FILE_IMPORT_PARTIAL_SUCCESS,
  FILE_IMPORT_SUCCESS,
  FOLDER_NAME_EMPTY_ERROR,
  FULL_SCREEN_TOGGLE,
  GROUPS_MERGED,
  GROUPS_REPLACED,
  GROUP_NAMES_RESET,
  HELP,
  LIBRARY_URL,
  MOBILE_APP_URL,
  MUSIC_TOGGLE,
  numberToWord,
  PLAY,
  RESET,
  RESTORE_DATA,
  SELECT_GROUP_WITH_ATLEAST_ONE_MEMBER,
  SETTINGS,
  SETTINGS_LIST,
  SOUND_TOGGLE,
  STOP,
  themesList,
  THEMES_LIST,
  WEBSITE_URL,
} from "../utils/constants";
import { ReactComponent as Reset } from "../assets/controls/Restart-Draw.svg";
import { ReactComponent as Settings } from "../assets/controls/Settings.svg";
import { ReactComponent as Events } from "../assets/controls/Events.svg";
import { ReactComponent as Exit } from "../assets/controls/Exit.svg";
import { ReactComponent as MusicOn } from "../assets/controls/Music-On.svg";
import { ReactComponent as MusicOff } from "../assets/controls/Music-Off.svg";
import { ReactComponent as SoundOn } from "../assets/controls/Sound-On.svg";
import { ReactComponent as SoundOff } from "../assets/controls/Sound-Off.svg";
import { ReactComponent as FAQ } from "../assets/controls/FAQ.svg";
import { ReactComponent as Groups } from "../assets/controls/Groups.svg";
import { ReactComponent as Help } from "../assets/controls/Help.svg";
import { ReactComponent as DrawName } from "../assets/controls/Draw-Name.svg";
import { ReactComponent as DrawNamePressed } from "../assets/controls/Draw-Name-Pressed.svg";
import { ReactComponent as StreamAudio } from "../assets/controls/Stream-Audio.svg";
import { ReactComponent as MirrorScreen } from "../assets/controls/Mirror-Screen.svg";
import { ReactComponent as Folder } from "../assets/Folder.svg";
import { ReactComponent as File } from "../assets/File.svg";
import "../App.scss";
import "../Responsive.scss";
import { CommonState } from "../store/types/common";
import { useDispatch, useSelector } from "react-redux";
import { GroupState } from "../store/types/group";
import { Button, Modal } from "react-bootstrap";
import {
  setFullScreenMode,
  setSelectedTheme,
  setToken,
} from "../store/actions/common";
import { setGroups } from "../store/actions/group";

import axios from "axios";
import {
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_DROPBOX_BASE_URL,
  REACT_APP_DROPBOX_CONTENT_BASE_URL,
  REACT_APP_DROPBOX_REDIRECT_PATH,
  REACT_APP_RNS_BASE_URL,
} from "../utils/config";
import MessageModal from "../components/MessageModal";
import {
  FaCloudDownloadAlt,
  FaCloudUploadAlt,
  FaDropbox,
  FaFileDownload,
  FaFileUpload,
} from "react-icons/fa";
import { ImPower } from "react-icons/im";
import { setSound, setMusic } from "../store/actions/common";
import { EventState } from "../store/types/event";
import { setEvents } from "../store/actions/event";

export enum FILE_IMPORT_STATUS {
  NOT_DEFINED,
  SUCCESS,
  PARTIAL_SUCCESS,
  FAILURE,
}

type MainProps = {
  gotoScreen?: (val: string) => void;
  playClickSound: () => void;
  playNameSound: (id: number) => void;
  playBgSound: (id: number) => void;
  stopBgSound: (id: number) => void;
  bgSoundHandler: (flag: string) => void;
  isIos: boolean;
  borderWidth: number;
  borderRadius: number;
};

const Main: React.FC<MainProps> = ({
  gotoScreen = () => { },
  playClickSound = () => { },
  playNameSound = (id: number) => { },
  playBgSound = (id: number) => { },
  stopBgSound = (id: number) => { },
  bgSoundHandler = (flag: string) => { },
  isIos,
  borderWidth,
  borderRadius,
}) => {
  const { selectedTheme, fullScreenMode, token, sound, music } = useSelector(
    (state: { common: CommonState }) => state.common
  );

  const { groups, selectedGroupIndex } = useSelector(
    (state: { group: GroupState }) => state.group
  );
  const { events, selectedEventIndex } = useSelector(
    (state: { event: EventState }) => state.event
  );
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [modalPanel, setModalPanel] = useState(SETTINGS_LIST);
  const [displayingMember, setDisplayingMember] = useState(null);
  const dispatch = useDispatch();

  const [option, setOption] = useState(0);
  const [dropboxAuthCode, setDropboxAuthCode] = useState(null);
  const [filesList, setFilesList] = useState([]);
  const [importedCounts, setImportedCounts] = useState({
    groupsCount: 0,
    membersCount: 0,
  });
  const [importedCountsEvent, setImportedCountsEvent] = useState({
    eventsCount: 0,
    teamsCount: 0,
  });
  const [viewDetails, setViewDetails] = useState(false);
  const [dbxImportSuccess, setDbxImportSuccess] = useState(false);
  const [fileImportStatus, setFileImportStatus] = useState<FILE_IMPORT_STATUS>(
    FILE_IMPORT_STATUS.NOT_DEFINED
  );
  const [fileImportError, setFileImportError] = useState<any>([]);
  const [dbxImportedFile, setDbxImportedFile] = useState<any>(null);
  const [fileName, setFileName] = useState(EXPORT_IMPORT_FILE_DEFAULT_NAME);
  const [folderName, setFolderName] = useState("");
  const [currentPath, setCurrentPath] = useState<any>("");
  const [breadCrumbs, setBreadCrumbs] = useState<any>([]);
  const [plusIcon, setPlusIcon] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showExportConfirm, setShowExportConfirm] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [exportFileName, setExportFileName] = useState(
    EXPORT_IMPORT_FILE_DEFAULT_NAME
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [mainHeight, setMainHeight] = useState<number>(0);
  const inputFileRefGroups: React.RefObject<HTMLInputElement> =
    React.createRef();
  const inputFileRefEvents: React.RefObject<HTMLInputElement> =
    React.createRef();
  const mainRef: React.RefObject<HTMLInputElement> = React.createRef();
  const [fontSize, setFontSize] = useState(0);
  const [teamIndex, setTeamIndex] = useState(0); //team in which to add member name
  const [drawnMemberName, setDrawnMemberName] = useState<any>({});
  const [type, setType] = useState("");
  const [prefilledTeamArr, setPrefilledTeamArr] = useState<
    Array<Array<string>>
  >([]);

  let teams: Array<any> = [];
  if (events[selectedEventIndex] && events[selectedEventIndex].teams.length) {
    teams = events[selectedEventIndex]?.teams
      .filter((team: any) => team.selected)
      .map((team: any) => {
        return {
          name: team.name,
          members: [],
        };
      })
  }

  const [displayTeamsArr, setDisplayTeamsArr] = useState(teams || []);
  const [maxTeamMembers, setMaxTeamMembers] = useState<number>(0);
  const [teamsCount, setTeamsCount] = useState<number>(0);
  const [membersCount, setMembersCount] = useState<number>(0);
  let timeoutId: any = 0;
  let headerTimeoutId: any = 0;
  let bodyTimeoutId: any = 0;

  const gotoGroups = () => {
    gotoScreen("groups");
  };

  const gotoEvents = () => {
    gotoScreen("events");
  };

  const handleClickSound = () => {
    if (sound) {
      playClickSound();
    }
  };

  const updateTeamIndex = () => {
    setTeamIndex((currentIndex: number) => {
      if (currentIndex == displayTeamsArr.length - 1) {
        return 0;
      }
      return currentIndex + 1;
    });
  };

  const booleanFromString = (str: string) => {
    if (str == null) return false;
    str = str.toLowerCase().trim();
    if (!str || str == "no" || str == "false") return false;
    if (!isNaN(+str)) return !!+str;
    return true;
  };

  const createGroupsJSON = (arr: Array<any>) => {
    let groups: Array<any> = [];
    let indexMap: any = {};
    arr.forEach((row: string) => {
      const groupName: string = row[1];
      const memberName: string = row[0];
      if (indexMap[groupName] !== undefined) {
        groups[indexMap[groupName]].members.push({
          name: memberName,
          selected: true,
          displayed: 0,
        });
      } else {
        indexMap[groupName] = groups.length;
        groups.push({
          name: groupName,
          maxShowCount: 1,
          members: [
            {
              name: memberName,
              selected: true,
              displayed: 0,
            },
          ],
        });
      }
    });
    return groups;
  };

  const createEventsJSON = (arr: Array<any>) => {
    let events: Array<any> = [];
    let indexMap: any = {};
    arr.forEach((row: string) => {
      const eventName: string = row[1];
      const teamName: string = row[0];
      if (indexMap[eventName] !== undefined) {
        events[indexMap[eventName]].teams.push({
          name: teamName,
          selected: true,
          displayed: 0,
        });
      } else {
        indexMap[eventName] = events.length;
        events.push({
          name: eventName,
          maxShowCount: 1,
          teams: [
            {
              name: teamName,
              selected: true,
              displayed: 0,
            },
          ],
        });
      }
    });
    return events;
  };

  const isDuplicate = (memberName: string, groupName: string, arr: any) => {
    let duplicate: any = arr.filter((item: any) => {
      const _memberName = item[0];
      const _groupName = item[1];
      if (groupName === _groupName && memberName === _memberName) {
        return true;
      }
    });
    return duplicate.length > 0;
  };

  const csvValidation = (rawText: string) => {
    let errorArr = [];
    let validArr = [];
    let lines = rawText
      .split(/\n\r|\n|\r/)
      .filter((line) => line.trim() != "")
      .map(function (line) {
        return line.trim();
      });
    for (let i = 0; i < lines.length; ++i) {
      const line = lines[i];
      if (!line) errorArr.push({ LINE_EMPTY: i + 1 });
      else {
        const parts = line.split(/\s*,\s*/);
        const memberName = parts[0];
        const groupName = parts[1];
        if (parts.length != 2 && parts.length != 3) {
          errorArr.push({ LINE_MALFORMED: i + 1 });
        } else if (isDuplicate(memberName, groupName, validArr)) {
          errorArr.push({ MEMBER_DUPLICATE: i + 1 });
        } else {
          const disabled = parts.length > 2 && !booleanFromString(parts[2]);
          if (!groupName) errorArr.push({ GROUP_MISSING: i + 1 });
          if (!memberName) errorArr.push({ MEMBER_MISSING: i + 1 });
          if (groupName && memberName) validArr.push([memberName, groupName]);
        }
      }
    }
    return {
      errorArr: errorArr,
      validArr: createGroupsJSON(validArr),
    };
  };

  const csvValidationEvents = (rawText: string) => {
    let errorArr = [];
    let validArr = [];
    let lines = rawText
      .split(/\n\r|\n|\r/)
      .filter((line) => line.trim() != "")
      .map(function (line) {
        return line.trim();
      });
    for (let i = 0; i < lines.length; ++i) {
      const line = lines[i];
      if (!line) errorArr.push({ LINE_EMPTY: i + 1 });
      else {
        const parts = line.split(/\s*,\s*/);
        const teamName = parts[0];
        const eventName = parts[1];
        if (parts.length != 2 && parts.length != 3) {
          errorArr.push({ LINE_MALFORMED: i + 1 });
        } else if (isDuplicate(teamName, eventName, validArr)) {
          const disabled = parts.length > 2 && !booleanFromString(parts[2]);
          if (!eventName) errorArr.push({ GROUP_MISSING: i + 1 });
          if (!teamName) errorArr.push({ MEMBER_MISSING: i + 1 });
          if (eventName && teamName) validArr.push([teamName, eventName]);
        }
      }
    }
    return {
      errorArr: errorArr,
      validArr: createEventsJSON(validArr),
    };
  };

  const getCount = (arr: Array<any>) => {
    //get groups and members count
    let membersCount = arr.reduce((total: number, group: any) => {
      return group.members.length + total;
    }, 0);
    return {
      groupsCount: arr.length,
      membersCount: membersCount,
    };
  };
  const getCountEvent = (arr: Array<any>) => {
    //get events and teams count
    let teamsCount = arr.reduce((total: number, event: any) => {
      return event.teams.length + total;
    }, 0);
    return {
      eventsCount: arr.length,
      teamsCount: teamsCount,
    };
  };

  const onFileChange = (e: any) => {
    setType("groups");
    let files = e.target.files;
    let f = files[0];
    var reader = new FileReader();
    reader.onload = function (e: any) {
      let str = e.target.result;
      processImportedCSV(str);
    };
    reader.readAsText(f);
  };

  const onFileChangeEvents = (e: any) => {
    setType("events");
    let files = e.target.files;
    let f = files[0];
    var reader = new FileReader();
    reader.onload = function (e: any) {
      let str = e.target.result;
      processImportedCSVEvents(str);
    };
    reader.readAsText(f);
  };

  const checkAllDisplayed = () => {
    return groups[selectedGroupIndex].members.every(function (member: any) {
      return !member.selected || member.displayed > 0;
    });
  };

  const hasMembersToSelect = () => {
    return groups[selectedGroupIndex]?.members.some(function (member: any) {
      return member.selected;
    });
  };

  const selectRandomMember = () => {
    const displayTimes = groups[selectedGroupIndex].maxShowCount;
    const enabledMembers = groups[selectedGroupIndex].members.filter(function (
      member: any
    ) {
      return (
        member.selected &&
        (!displayTimes || displayTimes > (member.displayed || 0))
      );
    });
    const count = enabledMembers.length;
    if (count) {
      const member = enabledMembers[Math.floor(Math.random() * count)];
      member.displayed = (member.displayed || 0) + 1;
      return member;
    }
  };

  const resetDisplayed = () => {
    if (groups[selectedGroupIndex] && groups[selectedGroupIndex].members.length) {
      groups[selectedGroupIndex].members.forEach(function (member: any) {
        member.displayed = 0;
      });
      dispatch(setGroups([...groups]));
    }
  };

  const resetTeamDisplayed = () => {
    setDisplayTeamsArr(teams);
  };

  const select = () => {
    if (hasMembersToSelect()) {
      if (checkAllDisplayed()) {
        resetDisplayed();
        setShowMessageModal(true);
        setModalMessage(ALL_MEMBERS_DISPLAYED);
      } else {
        if (modalMessage !== "") {
          resetTeamDisplayed();
          setModalMessage("");
          setTeamIndex(0);
        }
        const member = selectRandomMember();
        setDrawnMemberName(member);
        setDisplayingMember(member.name);
      }
    } else {
      setShowMessageModal(true);
      setModalMessage(SELECT_GROUP_WITH_ATLEAST_ONE_MEMBER);
    }
  };

  const handleSelect = () => {
    if (displayingMember) {
      setDisplayingMember(null);
      displayTeamsArr[teamIndex]?.members.push(drawnMemberName.name);
      updateTeamIndex();
    } else {
      select();
    }
    setOption(Math.round(Math.random()));
  };

  const toggleSound = (sound: boolean) => {
    dispatch(setSound(!sound));
    handleClickSound();
  };

  const toggleMusic = (music: boolean) => {
    dispatch(setMusic(!music));
    handleClickSound();
  };

  const dbxConnect = () => {
    let strWindowFeatures: string =
      "location=yes,height=570,width=520,scrollbars=yes,status=yes";
    let child: any = window.open(
      `https://www.dropbox.com/oauth2/authorize?client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_RNS_BASE_URL}/${REACT_APP_DROPBOX_REDIRECT_PATH}&response_type=code`,
      "_blank",
      strWindowFeatures
    );

    let timer = setInterval(() => {
      if (child.closed && (window as any).dropboxAuthCode) {
        setDropboxAuthCode((window as any).dropboxAuthCode);
        setDropboxAuthCode(null);
        clearInterval(timer);
        setModalPanel(SETTINGS_LIST);
      }
    }, 500);
  };

  const dbxDisconnect = () => {
    axios
      .post(`${REACT_APP_DROPBOX_BASE_URL}/2/auth/token/revoke`, null, {
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //dbx disconnected
        dispatch(setToken(null));
      })
      .catch((error) => {
        dispatch(setToken(null));
      });
  };
  //get index of team with longest name
  const getTeamIndex = (displayTeamsArr: Array<any>) => {
    let maxNameLength = 0;
    let teamIndex = 0;
    displayTeamsArr.forEach((team: any, index: number) => {
      const nameLength = team.name.length;
      if (nameLength > maxNameLength) {
        maxNameLength = nameLength;
        teamIndex = index;
      }
    });
    return teamIndex;
  };

  //get longest name in members list
  const getLongestName = (arr: Array<any>) => {
    let maxNameLength = 0;
    let longestName = "";
    arr.forEach((member: any, index: number) => {
      const nameLength = member.name.length;
      if (nameLength > maxNameLength) {
        maxNameLength = nameLength;
        longestName = member.name;
      }
    });
    return new Array(longestName.length + 1).join("W");
  };

  const dbxGetFilesList = (path: string) => {
    setLoader(true);
    axios
      .post(
        `${REACT_APP_DROPBOX_BASE_URL}/2/files/list_folder`,
        { path },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setLoader(false);
        setFilesList(res.data.entries);
      })
      .catch((error) => {
        setLoader(false);
        const { status } = error.response;
        if (status === 401 || status === 403) {
          dbxConnect();
        }
      });
  };

  const processImportedCSV = (csvString: string) => {
    let resultObj = csvValidation(csvString);
    let errorArray: Array<any> = resultObj.errorArr;
    let groupsArray: Array<any> = resultObj.validArr;
    setModalPanel(EXPORT_FILE);
    if (groupsArray.length && errorArray.length) {
      setFileImportStatus(FILE_IMPORT_STATUS.PARTIAL_SUCCESS);
      setImportedCounts(getCount(groupsArray));
      setFileImportError(errorArray);
      setDbxImportedFile(JSONtoCSV(groupsArray));
    } else if (!groupsArray.length && errorArray.length) {
      setFileImportStatus(FILE_IMPORT_STATUS.FAILURE);
      setFileImportError(errorArray);
      setModalPanel(EXPORT_FILE);
    } else {
      const arr = CSVtoJSON(csvString);
      setImportedCounts(getCount(arr));
      setFileImportError([]);
      setFileImportStatus(FILE_IMPORT_STATUS.SUCCESS);
      setDbxImportedFile(csvString);
    }
  };

  const processImportedCSVEvents = (csvString: string) => {
    let resultObj = csvValidationEvents(csvString);
    let errorArray: Array<any> = resultObj.errorArr;
    let eventsArray: Array<any> = resultObj.validArr;
    setModalPanel(EXPORT_FILE);
    if (eventsArray.length && errorArray.length) {
      setFileImportStatus(FILE_IMPORT_STATUS.PARTIAL_SUCCESS);
      setImportedCountsEvent(getCountEvent(eventsArray));
      setFileImportError(errorArray);
      setDbxImportedFile(JSONtoCSVEvents(eventsArray));
    } else if (!eventsArray.length && errorArray.length) {
      setFileImportStatus(FILE_IMPORT_STATUS.FAILURE);
      setFileImportError(errorArray);
      setModalPanel(EXPORT_FILE);
    } else {
      const arr = CSVtoJSONEvents(csvString);
      setImportedCountsEvent(getCountEvent(arr));
      setFileImportError([]);
      setFileImportStatus(FILE_IMPORT_STATUS.SUCCESS);
      setDbxImportedFile(csvString);
    }
  };

  //calculate events and members count

  useEffect(() => {
    const teamsCount = events[selectedEventIndex]?.teams.filter(
      (team: any) => team.selected
    ).length;
    setTeamsCount(teamsCount);
    const membersCount = groups[selectedGroupIndex]?.members.filter(
      (member: any) => member.selected
    ).length;
    setMembersCount(membersCount);
  }, [teamsCount, membersCount]);

  // Calculate display name font size
  useEffect(() => {
    setFontSize(10);
    let inc = false;
    let dec = false;
    const adjustFontSize = (count: number, inc: boolean, dec: boolean) => {
      if (displayingMember) {
        let ele = document.getElementById("displaying-member")!;
        let clientWidth: number = ele?.clientWidth || 0;
        let scrollWidth: number = ele?.scrollWidth || 0;
        let clientHeight: number = ele?.clientHeight || 0;
        let scrollHeight: number = ele?.scrollHeight || 0;
        let style = window
          .getComputedStyle(ele, null)
          .getPropertyValue("font-size");
        let fontSize = parseFloat(style);
        if (
          scrollWidth == clientWidth &&
          scrollHeight == clientHeight &&
          count < 150 &&
          !dec
        ) {
          fontSize = fontSize + 1;
          ele.style.fontSize = `${fontSize}px`; //using react useState has very slow performance here
          count++;
          adjustFontSize(count, true, false);
        } else if (
          scrollWidth > clientWidth ||
          (scrollHeight > clientHeight && count < 150 && !inc)
        ) {
          fontSize = fontSize - 1;
          ele.style.fontSize = `${fontSize}px`;
          count++;
          adjustFontSize(count, false, true);
        }
      }
    };
    setTimeout(() => {
      adjustFontSize(0, false, false);
    }, 10);

    const resizeFunc = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        adjustFontSize(0, false, false);
      }, 50);
    };

    window.addEventListener("resize", resizeFunc);
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, [displayingMember]);

  // Calculate teams header text font size
  useEffect(() => {
    //get index of team with longest name
    const teamIndexWithLongestName = getTeamIndex(displayTeamsArr);
    let inc = false;
    let dec = false;
    const adjustFontSize = (count: number, inc: boolean, dec: boolean) => {
      let ele = document.getElementById(
        `header-fake-${teamIndexWithLongestName}`
      )!;
      let clientWidth: number = ele?.clientWidth || 0;
      let scrollWidth: number = ele?.scrollWidth || 0;
      let clientHeight: number = ele?.clientHeight || 0;
      let scrollHeight: number = ele?.scrollHeight || 0;

      let style = "0";
      if (ele) {
        style = window.getComputedStyle(ele, null)
          .getPropertyValue("font-size");
      }

      let fontSize = parseFloat(style);
      if (
        scrollWidth == clientWidth &&
        scrollHeight == clientHeight &&
        count < 150 &&
        !dec
      ) {
        fontSize = fontSize + 1;
        displayTeamsArr.forEach((team: any, index: number) => {
          let fakeHeader: any = document.getElementById(
            `header-fake-${index}`
          )!;
          let header: any = document.getElementById(`header-${index}`)!;
          fakeHeader.style.fontSize = `${fontSize}px`;
          header.style.fontSize = `${fontSize}px`;
        });
        count++;
        adjustFontSize(count, true, false);
      } else if (
        scrollWidth > clientWidth ||
        (scrollHeight > clientHeight && count < 150 && !inc)
      ) {
        fontSize = fontSize - 1;
        displayTeamsArr.forEach((team: any, index: number) => {
          let fakeHeader: any = document.getElementById(
            `header-fake-${index}`
          )!;
          let header: any = document.getElementById(`header-${index}`)!;
          fakeHeader.style.fontSize = `${fontSize}px`;
          header.style.fontSize = `${fontSize}px`;
        });
        count++;
        adjustFontSize(count, false, true);
      }
    };
    setTimeout(() => {
      adjustFontSize(0, false, false);
    }, 100);

    const resizeFunc = () => {
      clearTimeout(headerTimeoutId);
      headerTimeoutId = setTimeout(() => {
        adjustFontSize(0, false, false);
      }, 50);
    };

    window.addEventListener("resize", resizeFunc);
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, [displayTeamsArr.length]);

  // Calculate team members text font size
  useEffect(() => {
    let inc = false;
    let dec = false;
    const adjustFontSize = (count: number, inc: boolean, dec: boolean) => {
      let ele = document.getElementById(`body-fake-0`)!;
      //let fakeTeamContainer = document.getElementById(`team-fake-0`)!;
      let fakeHeader = document.getElementById(`header-fake-0`)!;
      let clientWidth: number = ele?.clientWidth || 0;
      let scrollWidth: number = ele?.scrollWidth || 0;
      //instead of client height it should be client height divided by number of maximum members in that team
      let clientHeight: number = ele?.clientHeight || 0;
      let scrollHeight: number = ele?.scrollHeight || 0;
      let style = "0";
      if (ele) {
        style = window.getComputedStyle(ele, null)
          .getPropertyValue("font-size");
      }
      let fontSize = parseFloat(style);
      if (
        scrollWidth == clientWidth &&
        scrollHeight == clientHeight &&
        count < 200 &&
        !dec
      ) {
        fontSize = fontSize + 1;
        displayTeamsArr.forEach((team: any, index: number) => {
          let fakeBody: any = document.getElementById(`body-fake-${index}`)!;
          let body: any = document.getElementById(`body-${index}`)!;
          fakeBody.style.fontSize = `${fontSize}px`;
          body.style.fontSize = `${fontSize}px`;
        });
        count++;
        adjustFontSize(count, true, false);
      } else if (
        scrollWidth > clientWidth ||
        (scrollHeight > clientHeight && count < 200 && !inc)
      ) {
        fontSize = fontSize - 1;
        displayTeamsArr.forEach((team: any, index: number) => {
          let fakeBody: any = document.getElementById(`body-fake-${index}`)!;
          let body: any = document.getElementById(`body-${index}`)!;
          fakeBody.style.fontSize = `${fontSize}px`;
          body.style.fontSize = `${fontSize}px`;
        });
        count++;
        adjustFontSize(count, false, true);
      }
    };
    setTimeout(() => {
      adjustFontSize(0, false, false);
    }, 100);

    const resizeFunc = () => {
      clearTimeout(bodyTimeoutId);
      bodyTimeoutId = setTimeout(() => {
        adjustFontSize(0, false, false);
      }, 50);
    };

    window.addEventListener("resize", resizeFunc);
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, [displayTeamsArr.length, maxTeamMembers]);

  useEffect(() => {
    let breadCrumbs: Array<string> = currentPath
      .split("/")
      .map((b: string, index: number, arr: Array<string>) => {
        return {
          index: index,
          label: b,
          path: arr.slice(0, index + 1).join("/"),
        };
      });
    setBreadCrumbs(breadCrumbs);
  }, [currentPath]);

  const dbxDownloadFile = (file: any) => {
    setLoader(true);
    axios
      .post(`${REACT_APP_DROPBOX_CONTENT_BASE_URL}/2/files/download`, null, {
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "",
          "Dropbox-API-Arg": `{"path": "${file.id}"}`,
        },
      })
      .then((res) => {
        let csvString = res.data;
        processImportedCSV(csvString);
      })
      .catch((error) => {
        setLoader(false);
        const { status } = error.response;
        if (status === 401 || status === 403) {
          dbxConnect();
        }
      });
  };

  const dbxDownloadFileEvents = (file: any) => {
    setLoader(true);
    axios
      .post(`${REACT_APP_DROPBOX_CONTENT_BASE_URL}/2/files/download`, null, {
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "",
          "Dropbox-API-Arg": `{"path": "${file.id}"}`,
        },
      })
      .then((res) => {
        let csvString = res.data;
        processImportedCSVEvents(csvString);
      })
      .catch((error) => {
        setLoader(false);
        const { status } = error.response;
        if (status === 401 || status === 403) {
          dbxConnect();
        }
      });
  };

  const JSONtoCSV = (groups: any) => {
    return groups

      .filter((group: any) => group.members.length)
      .reduce((combined: string, group: any) => {
        return combined.concat(
          "\n",
          group.members
            .map((member: any) => {
              return [member.name, group.name].join();
            })
            .join("\n")
        );
      }, "")
      .replace(/^\s+|\s+$/g, "");
  };

  const JSONtoCSVEvents = (groups: any) => {
    return groups

      .filter((group: any) => group.teams.length)
      .reduce((combined: string, group: any) => {
        return combined.concat(
          "\n",
          group.teams
            .map((member: any) => {
              return [member.name, group.name].join();
            })
            .join("\n")
        );
      }, "")
      .replace(/^\s+|\s+$/g, "");
  };

  const CSVtoJSON = (csvString: string) => {
    let rows: Array<string> = csvString.split(/\r?\n|\r/);
    let groups: Array<any> = [];
    let indexMap: any = {};
    rows.forEach((row: string) => {
      const rowItems: Array<string> = row.split(",");
      const groupName: string = rowItems[1];
      const memberName: string = rowItems[0];
      if (indexMap[groupName] !== undefined) {
        groups[indexMap[groupName]].members.push({
          name: memberName,
          selected: true,
          displayed: 0,
        });
      } else {
        indexMap[groupName] = groups.length;
        groups.push({
          name: groupName,
          maxShowCount: 1,
          members: [
            {
              name: memberName,
              selected: true,
              displayed: 0,
            },
          ],
        });
      }
    });
    return groups;
  };

  const CSVtoJSONEvents = (csvString: string) => {
    let rows: Array<string> = csvString.split(/\r?\n|\r/);
    let events: Array<any> = [];
    let indexMap: any = {};
    rows.forEach((row: string) => {
      const rowItems: Array<string> = row.split(",");
      const eventName: string = rowItems[1];
      const teamName: string = rowItems[0];
      if (indexMap[eventName] !== undefined) {
        events[indexMap[eventName]].teams.push({
          name: teamName,
          selected: true,
        });
      } else {
        indexMap[eventName] = events.length;
        events.push({
          name: eventName,
          maxShowCount: 1,
          teams: [
            {
              name: teamName,
              selected: true,
            },
          ],
        });
      }
    });
    return events;
  };

  const generateCSVFile = (groups: any) => {
    //generate CSV string
    let csvContent = JSONtoCSV(groups);
    //download csv
    var encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    if (exportFileName === EXPORT_IMPORT_FILE_DEFAULT_NAME) {
      link.setAttribute("download", exportFileName);
    } else if (exportFileName.lastIndexOf(".") == -1) {
      link.setAttribute("download", exportFileName + ".csv");
    } else if (
      exportFileName.substring(
        exportFileName.lastIndexOf(".") + 1,
        exportFileName.length
      ) === "csv"
    ) {
      link.setAttribute("download", exportFileName);
    } else {
      link.setAttribute("download", exportFileName + ".csv");
    }

    document.body.appendChild(link);
    link.click();
    setExportFileName(EXPORT_IMPORT_FILE_DEFAULT_NAME);
  };

  const formatFileName = (fileName: string) => {
    const arr = fileName.split(".");
    if (arr[arr.length - 1].toLowerCase() === "csv") {
      return fileName;
    } else {
      return `${fileName}.csv`;
    }
  };

  const generateCSVFileEvents = (groups: any) => {
    //generate CSV string
    let csvContent = JSONtoCSVEvents(events);
    //download csv
    var encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    if (exportFileName === EXPORT_IMPORT_FILE_DEFAULT_NAME) {
      link.setAttribute("download", exportFileName);
    } else if (exportFileName.lastIndexOf(".") == -1) {
      link.setAttribute("download", exportFileName + ".csv");
    } else if (
      exportFileName.substring(
        exportFileName.lastIndexOf(".") + 1,
        exportFileName.length
      ) === "csv"
    ) {
      link.setAttribute("download", exportFileName);
    } else {
      link.setAttribute("download", exportFileName + ".csv");
    }

    document.body.appendChild(link);
    link.click();
    setExportFileName(EXPORT_IMPORT_FILE_DEFAULT_NAME);
  };

  const dbxUpload = (path: any, fileName: string) => {
    const strPath: string = path;
    const csvContent = JSONtoCSV(groups);
    setLoader(true);
    axios
      .post(
        `${REACT_APP_DROPBOX_CONTENT_BASE_URL}/2/files/upload`,
        csvContent,
        {
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "application/octet-stream",
            "Dropbox-API-Arg": `{"path": "${strPath}/${formatFileName(
              fileName
            )}","autorename": true}`,
          },
        }
      )
      .then((res) => {
        setLoader(true);
        dbxGetFilesList(strPath);
        setFileName("");
        setFolderName("");
      })
      .catch((error) => {
        setLoader(false);
        const { status } = error.response;
        if (status === 401 || status === 403) {
          dbxConnect();
        } else if (status === 409) {
          setShowMessageModal(true);
          setModalMessage(FILE_ALREADY_EXISTS);
        }
      });
  };

  const dbxUploadEvents = (path: any, fileName: string) => {
    const strPath: string = path;
    const csvContent = JSONtoCSVEvents(events);
    setLoader(true);
    axios
      .post(
        `${REACT_APP_DROPBOX_CONTENT_BASE_URL}/2/files/upload`,
        csvContent,
        {
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "application/octet-stream",
            "Dropbox-API-Arg": `{"path": "${strPath}/${formatFileName(
              fileName
            )}","autorename": true}`,
          },
        }
      )
      .then((res) => {
        setLoader(true);
        dbxGetFilesList(strPath);
        setFileName("");
        setFolderName("");
      })
      .catch((error) => {
        setLoader(false);
        const { status } = error.response;
        if (status === 401 || status === 403) {
          dbxConnect();
        } else if (status === 409) {
          setShowMessageModal(true);
          setModalMessage(FILE_ALREADY_EXISTS);
        }
      });
  };

  const dbxCreateFolder = (path: any, folderName: string) => {
    setLoader(true);
    const strPath: string = path;
    axios
      .post(
        `${REACT_APP_DROPBOX_BASE_URL}/2/files/create_folder_v2`,
        {
          autorename: true,
          path: strPath + "/" + folderName,
        },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then((res: any) => {
        setLoader(false);
        dbxGetFilesList(strPath);
        setFileName("");
        setFolderName("");
      })
      .catch((error) => {
        setLoader(false);
        const { status } = error.response;
        if (status === 401 || status === 403) {
          dbxConnect();
        }
      });
  };

  const replaceDbxFile = () => {
    if (dbxImportedFile) {
      let groups: Array<any> = CSVtoJSON(dbxImportedFile);
      dispatch(setGroups(groups));
      setShowMessageModal(true);
      setModalMessage(GROUPS_REPLACED);
      setFileImportStatus(FILE_IMPORT_STATUS.NOT_DEFINED);
      setShowSettingsModal(false);
      setDbxImportedFile(null);
      setModalPanel(SETTINGS_LIST);
    } else {
      setShowMessageModal(true);
      setModalMessage(FILE_IMPORT_FAILED);
    }
  };

  const replaceDbxFileEvents = () => {
    if (dbxImportedFile) {
      let events: Array<any> = CSVtoJSONEvents(dbxImportedFile);
      dispatch(setEvents(events));
      setShowMessageModal(true);
      setModalMessage(EVENTS_REPLACED);
      setFileImportStatus(FILE_IMPORT_STATUS.NOT_DEFINED);
      setShowSettingsModal(false);
      setDbxImportedFile(null);
      setModalPanel(SETTINGS_LIST);
    } else {
      setShowMessageModal(true);
      setModalMessage(FILE_IMPORT_FAILED);
    }
  };

  const mergeDbxFile = () => {
    if (dbxImportedFile) {
      let arr1: Array<any> = groups;
      let arr2: Array<any> = [];
      arr2 = CSVtoJSON(dbxImportedFile);
      arr2.forEach((item2: any) => {
        const index = arr1.findIndex((item1: any) => item1.name === item2.name);
        if (index === -1) {
          arr1.push(item2);
        }
      });

      arr1.forEach((item1: any) => {
        const index = arr2.findIndex((item2: any) => item2.name === item1.name);
        if (index !== -1) {
          arr2[index].members.forEach((member2: any) => {
            const memberIndex = item1.members.findIndex(
              (member1: any) => member1.name === member2.name
            );
            if (memberIndex === -1) {
              item1.members.push(member2);
            }
          });
        }
      });

      dispatch(setGroups(groups));
      setShowMessageModal(true);
      setModalMessage(GROUPS_MERGED);
      setFileImportStatus(FILE_IMPORT_STATUS.NOT_DEFINED);
      setDbxImportedFile(null);
      setModalPanel(SETTINGS_LIST);
      setShowSettingsModal(false);
    } else {
      setShowMessageModal(true);
      setModalMessage(FILE_IMPORT_FAILED);
    }
  };

  const mergeDbxFileEvents = () => {
    if (dbxImportedFile) {
      let arr1: Array<any> = events;
      let arr2: Array<any> = [];
      arr2 = CSVtoJSONEvents(dbxImportedFile);
      arr2.forEach((item2: any) => {
        const index = arr1.findIndex((item1: any) => item1.name === item2.name);
        if (index === -1) {
          arr1.push(item2);
        }
      });

      arr1.forEach((item1: any) => {
        const index = arr2.findIndex((item2: any) => item2.name === item1.name);
        if (index !== -1) {
          arr2[index].teams.forEach((team2: any) => {
            const teamIndex = item1.teams.findIndex(
              (team1: any) => team1.name === team2.name
            );
            if (teamIndex === -1) {
              item1.teams.push(team2);
            }
          });
        }
      });

      dispatch(setEvents(events));
      setShowMessageModal(true);
      setModalMessage(EVENTS_MERGED);
      setFileImportStatus(FILE_IMPORT_STATUS.NOT_DEFINED);
      setDbxImportedFile(null);
      setModalPanel(SETTINGS_LIST);
      setShowSettingsModal(false);
    } else {
      setShowMessageModal(true);
      setModalMessage(FILE_IMPORT_FAILED);
    }
  };

  const gotoURL = (url: string, target: string) => {
    window.open(url, target);
  };

  const nameSoundHandler = (flag: string) => {
    if (flag === PLAY) {
      playNameSound(selectedTheme.id);
    } else if (flag === STOP) {
      //stopNameSound(selectedTheme.id);
    }
  };

  useEffect(() => {
    if (dropboxAuthCode) {
      axios
        .post(
          `${REACT_APP_DROPBOX_BASE_URL}/oauth2/token`,
          new URLSearchParams({
            grant_type: "authorization_code",
            code: dropboxAuthCode,
            redirect_uri: `${REACT_APP_RNS_BASE_URL}/${REACT_APP_DROPBOX_REDIRECT_PATH}`,
            client_id: REACT_APP_CLIENT_ID,
            client_secret: REACT_APP_CLIENT_SECRET,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (res?.data?.access_token) {
            dispatch(setToken(res?.data?.access_token));
          }
        });
    }
  }, [dropboxAuthCode]);

  useEffect(() => {
    if (displayingMember && sound) {
      nameSoundHandler(PLAY);
    }
  }, [displayingMember]);

  const calculateHeight = (mainHeight: number, reduceByPerc: number) => {
    return mainHeight - (reduceByPerc * mainHeight) / 100;
  };

  useEffect(() => {
    // const handleResize = () => {
    if (mainRef?.current?.clientHeight == undefined) {
      setMainHeight(0);
    } else {
      setMainHeight(mainRef?.current?.clientHeight);
    }
    //  }
    // window.addEventListener('resize', handleResize)
    // return () => {
    //   window.removeEventListener('resize', handleResize)
    // }
  });

  const renderFileControls = () => {
    return (
      <div className="d-flex align-item-center justify-content-center pt-3">
        <button
          style={{ marginRight: 15 }}
          className="btn btn-primary"
          onClick={() => {
            replaceDbxFile();
          }}
        >
          Replace
        </button>
        <button
          style={{ marginRight: 15 }}
          className="btn btn-secondary"
          onClick={() => {
            setFileImportStatus(FILE_IMPORT_STATUS.NOT_DEFINED);
            setModalPanel(SETTINGS_LIST);
          }}
        >
          Discard
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            mergeDbxFile();
          }}
        >
          Merge
        </button>
      </div>
    );
  };

  const renderFileControlsEvents = () => {
    return (
      <div className="d-flex align-item-center justify-content-center pt-3">
        <button
          style={{ marginRight: 15 }}
          className="btn btn-primary"
          onClick={() => {
            replaceDbxFileEvents();
          }}
        >
          Replace
        </button>
        <button
          style={{ marginRight: 15 }}
          className="btn btn-secondary"
          onClick={() => {
            setFileImportStatus(FILE_IMPORT_STATUS.NOT_DEFINED);
            setModalPanel(SETTINGS_LIST);
          }}
        >
          Discard
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            mergeDbxFileEvents();
          }}
        >
          Merge
        </button>
      </div>
    );
  };

  const prefillMembers = () => {
    const teamsLength = events[selectedEventIndex].teams.filter(
      (team: any) => team.selected
    ).length;
    let arr: any = Array.from(Array(teamsLength), () => []);
    let n = 0;
    const members = groups[selectedGroupIndex].members.filter(
      (member: any) => member.selected
    );
    const longestName: string = getLongestName(members);
    members.forEach((member: any, index: number) => {
      arr[n].push(longestName);
      if (n == arr.length - 1) {
        n = 0;
      } else {
        n++;
      }
    });
    return arr;
  };

  const getMaxTeamMembers = (arr: Array<Array<string>>) => {
    return Math.max(
      ...arr.map((arr: Array<string>) => {
        return arr.length;
      })
    );
  };

  const gridClass = () => {
    if (
      (teamsCount == 2 && membersCount > 20) ||
      (teamsCount == 4 && membersCount > 20) ||
      (teamsCount == 5 && membersCount > 15) ||
      (teamsCount == 6 && membersCount > 18) ||
      (teamsCount == 7 && membersCount > 21) ||
      (teamsCount == 8 && membersCount > 16)
    ) {
      return "grid";
    }
  };

  useEffect(() => {
    if (groups[selectedGroupIndex] && groups[selectedGroupIndex].members.length && events[selectedEventIndex] && events[selectedEventIndex].teams.length) {
      const arr = prefillMembers();
      const maxMembers = getMaxTeamMembers(arr);
      setMaxTeamMembers(maxMembers);
      setPrefilledTeamArr(arr);
    }
  }, [displayTeamsArr.length]);

  return (
    <>
      <div className={`main-screen ${selectedTheme.name}`} ref={mainRef}>
        {showOverlay && (
          <>
            <div className="overlay-back"></div>
            <div
              className="overlay-front"
              onClick={() => {
                setShowOverlay(false);
              }}
            ></div>
          </>
        )}
        {groups[selectedGroupIndex] &&
          groups[selectedGroupIndex].displayCount &&
          groups[selectedGroupIndex].maxShowCount == 1 && (
            <div className="members-count">
              {
                groups[selectedGroupIndex].members.filter(
                  (member: any) => member.displayed > 0
                ).length
              }{" "}
              of{" "}
              {
                groups[selectedGroupIndex].members.filter(
                  (member: any) => member.selected
                ).length
              }
            </div>
          )}
        <div
          id="name-container"
          className={`${displayingMember ? `option-${option} active active-add` : ""
            }`}
        >
          <div
            className="content"
            id="displaying-member"
            style={{ fontSize: fontSize }}
          >
            {displayingMember}
          </div>
        </div>
        <div
          className={`teams-container original ${numberToWord[displayTeamsArr.length]
            }`}
        >
          {displayTeamsArr.map((team: any, index: number) => {
            return (
              <div
                className="team"
                key={index}
                style={{
                  borderRadius: borderRadius,
                }}
              >
                <div
                  className="shadow-in"
                  style={{
                    borderRadius: borderRadius,
                  }}
                ></div>
                <div
                  className="team-inner"
                  id={`team-${index}`}
                  style={{
                    borderWidth: borderWidth,
                    borderRadius: borderRadius,
                  }}
                >
                  <div
                    className="shadow-out"
                    style={{
                      borderRadius: borderRadius - (borderWidth / 1.5),
                    }}
                  ></div>
                  <div className="header" id={`header-${index}`} style={{borderTopLeftRadius:borderRadius - (borderWidth / 1.5),borderTopRightRadius:borderRadius - (borderWidth / 1.5)}}>
                    {team.name}
                  </div>
                  <div className={`body ${gridClass()}`} id={`body-${index}`}>
                    {team.members.map((member: string, index: number) => {
                      return <div key={index}>{member}</div>;
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={`teams-container fake ${numberToWord[displayTeamsArr.length]
            }`}
        >
          {displayTeamsArr.map((team: any, index: number) => {
            return (
              <div
                className="team"
                key={index}
                style={{
                  borderRadius: borderRadius,
                }}
              >
                <div
                  className="shadow-in"
                  style={{
                    borderRadius: borderRadius,
                  }}
                ></div>
                <div
                  className="team-inner"
                  id={`team-fake-${index}`}
                  style={{
                    borderWidth: borderWidth,
                    borderRadius: borderRadius,
                  }}
                >
                  <div
                    className="shadow-out"
                    style={{
                      borderRadius: borderRadius,
                    }}
                  ></div>
                  <div className="header" id={`header-fake-${index}`}>
                    {team.name}
                  </div>
                  <div
                    className={`body ${gridClass()}`}
                    id={`body-fake-${index}`}
                  >
                    {prefilledTeamArr[index]?.map(
                      (member: string, memberIndex: number) => {
                        return (
                          <div key={`prefill-${index}-${memberIndex}`}>
                            {member}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="controls-wrapper">
          <div className="controls-container left">
            <div
              className="control"
              onKeyUp={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  handleClickSound();
                  gotoURL(LIBRARY_URL, "_self");
                }
              }}
              onMouseUp={() => {
                handleClickSound();
                gotoURL(LIBRARY_URL, "_self");
              }}
            >
              <Exit title="Exit" className="icon" />
              {showOverlay && (
                <div
                  className="line left"
                  style={{ height: calculateHeight(mainHeight, 20) }}
                >
                  {EXIT_APPLICATION}
                </div>
              )}
            </div>
            <div
              className="control"
              onKeyUp={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  setShowOverlay(!showOverlay);
                }
              }}
              onMouseUp={() => {
                setShowOverlay(!showOverlay);
              }}
            >
              <Help title="Help" className="icon" />
              {showOverlay && (
                <div
                  className="line left"
                  style={{ height: calculateHeight(mainHeight, 40) }}
                >
                  {HELP}
                </div>
              )}
            </div>
            <div
              className="control"
              onClick={() => window.open(FAQ_URL, "_blank")}
            >
              <FAQ title="Frequently Asked Questions" className="icon" />
              {showOverlay && (
                <div
                  className="line left"
                  style={{ height: calculateHeight(mainHeight, 60) }}
                >
                  {FAQ_LABEL}
                </div>
              )}
            </div>
            <div
              className="control"
              onKeyUp={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  handleClickSound();
                  dispatch(setFullScreenMode(!fullScreenMode));
                }
              }}
              onMouseUp={() => {
                handleClickSound();
                dispatch(setFullScreenMode(!fullScreenMode));
              }}
            >
              <MirrorScreen className="icon" title="Full Screen" />
              {showOverlay && (
                <div
                  className="line left"
                  style={{ height: calculateHeight(mainHeight, 80) }}
                >
                  {FULL_SCREEN_TOGGLE}
                </div>
              )}
            </div>
            <div
              className="control"
              onKeyUp={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  handleClickSound();
                  resetDisplayed();
                  resetTeamDisplayed();
                  setShowMessageModal(true);
                  setModalMessage(GROUP_NAMES_RESET);
                }
              }}
              onMouseUp={() => {
                handleClickSound();
                resetDisplayed();
                resetTeamDisplayed();
                setShowMessageModal(true);
                setModalMessage(GROUP_NAMES_RESET);
              }}
            >
              <Reset title="Reset" className="icon" />
              {showOverlay && (
                <div
                  className="line right"
                  style={{ height: calculateHeight(mainHeight, 80) }}
                >
                  {RESET}
                </div>
              )}
            </div>
          </div>
          <div className="controls-container center">
            <div
              className="control"
              onKeyUp={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  setPlusIcon(false);
                  handleClickSound();
                  handleSelect();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  setPlusIcon(true);
                }
              }}
              onBlur={(e) => {
                setPlusIcon(false);
              }}
              onMouseDown={() => {
                setPlusIcon(true);
              }}
              onMouseUp={() => {
                setPlusIcon(false);
                handleClickSound();
                handleSelect();
              }}
            >
              {isIos ? (
                <>
                  <div className="position-relative invisible">
                    <DrawNamePressed className="icon" />
                  </div>
                  <div
                    className={`position-absolute ${plusIcon ? "visible" : "invisible"
                      }`}
                    style={{ top: 0, width: "95%", height: "95%" }}
                  >
                    <DrawNamePressed title="Select" className="icon" />
                  </div>
                  <div
                    className={`position-absolute ${plusIcon ? "invisible" : "visible"
                      }`}
                    style={{ top: 0, width: "95%", height: "95%" }}
                  >
                    <DrawName title="Select" className="icon" />
                  </div>
                </>
              ) : plusIcon ? (
                <DrawNamePressed title="Select" className="icon" />
              ) : (
                <DrawName title="Select" className="icon" />
              )}
            </div>
          </div>
          <div className="controls-container right">
            <div
              className="control"
              onKeyUp={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  toggleSound(sound);
                }
              }}
              onMouseUp={() => {
                toggleSound(sound);
              }}
            >
              {isIos ? (
                <>
                  <div className="position-relative invisible">
                    <SoundOff className="icon" />
                  </div>
                  <div
                    className={`position-absolute ios-icon ${sound ? "visible" : "invisible"
                      }`}
                  >
                    <SoundOn title="Toggle Sound" className="icon" />
                  </div>
                  <div
                    className={`position-absolute ios-icon ${sound ? "invisible" : "visible"
                      }`}
                  >
                    <SoundOff title="Toggle Sound" className="icon" />
                  </div>
                </>
              ) : (
                <div>
                  {sound ? (
                    <SoundOn title="Toggle Sound" className="icon" />
                  ) : (
                    <SoundOff title="Toggle Sound" className="icon" />
                  )}
                </div>
              )}
              {showOverlay && (
                <div
                  className="line right"
                  style={{ height: calculateHeight(mainHeight, 65) }}
                >
                  {SOUND_TOGGLE}
                </div>
              )}
            </div>
            <div
              className="control"
              onKeyUp={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  toggleMusic(music);
                }
              }}
              onMouseUp={() => {
                toggleMusic(music);
              }}
            >
              {isIos ? (
                <>
                  <div className="position-relative invisible">
                    <MusicOff className="icon" />
                  </div>
                  <div
                    className={`position-absolute ios-icon ${music ? "visible" : "invisible"
                      }`}
                  >
                    <MusicOn title="Toggle Music" className="icon" />
                  </div>
                  <div
                    className={`position-absolute ios-icon ${music ? "invisible" : "visible"
                      }`}
                  >
                    <MusicOff title="Toggle Music" className="icon" />
                  </div>
                </>
              ) : (
                <>
                  {music ? (
                    <MusicOn title="Toggle Music" className="icon" />
                  ) : (
                    <MusicOff title="Toggle Music" className="icon" />
                  )}
                </>
              )}

              {showOverlay && (
                <div
                  className="line right"
                  style={{ height: calculateHeight(mainHeight, 50) }}
                >
                  {MUSIC_TOGGLE}
                </div>
              )}
            </div>
            <div
              className="control"
              onKeyUp={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  handleClickSound();
                  gotoEvents();
                }
              }}
              onMouseUp={() => {
                handleClickSound();
                gotoEvents();
              }}
            >
              <Events title="Events" className="icon" />
              {showOverlay && (
                <div
                  className="line right"
                  style={{ height: calculateHeight(mainHeight, 40) }}
                >
                  {EDIT_EVENTS}
                </div>
              )}
            </div>

            <div
              className="control"
              onKeyUp={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  handleClickSound();
                  gotoGroups();
                }
              }}
              onMouseUp={() => {
                handleClickSound();
                gotoGroups();
              }}
            >
              <Groups title="Groups" className="icon" />
              {showOverlay && (
                <div
                  className="line right"
                  style={{ height: calculateHeight(mainHeight, 30) }}
                >
                  {EDIT_GROUPS}
                </div>
              )}
            </div>
            <div
              className={`control`}
              onKeyUp={(e) => {
                if (e.key === " " || e.key === "Enter") {
                  handleClickSound();
                  setShowSettingsModal(true);
                }
              }}
              onMouseUp={() => {
                handleClickSound();
                setShowSettingsModal(true);
              }}
            >
              <Settings title="Settings" className="icon" />
              {showOverlay && (
                <div
                  className="line right"
                  style={{ height: calculateHeight(mainHeight, 20) }}
                >
                  {SETTINGS}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="settings-modal"
        show={showSettingsModal}
        onHide={() => {
          setShowSettingsModal(false);
        }}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton={false}>
          {modalPanel === THEMES_LIST ? (
            <>
              <button
                className="btn btn-link btn-custom"
                onClick={() => {
                  setModalPanel(SETTINGS_LIST);
                }}
              >
                <span className="left-chevron">&lsaquo;</span>Settings
              </button>
              <span>Backgrounds</span>
              <button
                className="btn btn-link btn-custom"
                onClick={() => {
                  setShowSettingsModal(false);
                  setModalPanel(SETTINGS_LIST);
                }}
              >
                Done
              </button>
            </>
          ) : modalPanel === EXPORT_FILE || modalPanel === EXPORT_FILE_LOAD ? (
            <>
              <button
                className="btn btn-link btn-custom"
                onClick={() => {
                  setModalPanel(SETTINGS_LIST);
                }}
              >
                <span className="left-chevron">&lsaquo;</span>Settings
              </button>
              <span>Load/Save Files</span>
              <button
                className="btn btn-link btn-custom"
                onClick={() => {
                  setShowSettingsModal(false);
                  setModalPanel(SETTINGS_LIST);
                }}
              >
                Done
              </button>
            </>
          ) : modalPanel === RESTORE_DATA || modalPanel === BACKUP_DATA ? (
            <>
              <button
                className="btn btn-link btn-custom"
                onClick={() => {
                  setModalPanel(SETTINGS_LIST);
                }}
              >
                <span className="left-chevron">&lsaquo;</span>Settings
              </button>
              <span>{modalPanel}</span>
              <button
                className="btn btn-link btn-custom"
                onClick={() => {
                  setShowSettingsModal(false);
                  setModalPanel(SETTINGS_LIST);
                }}
              >
                Done
              </button>
            </>
          ) : (
            <>
              <span style={{ width: 50 }}></span>
              <span>Settings</span>
              <button
                className="btn btn-link btn-custom"
                onClick={() => {
                  setShowSettingsModal(false);
                  setModalPanel(SETTINGS_LIST);
                }}
              >
                Done
              </button>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          {modalPanel === SETTINGS_LIST && (
            <>
              <div className="hr"></div>
              <div className="settings-list">
                <div className="settings-list-item">
                  <div className="settings-list-item--title">Themes</div>
                  <div className="settings-list-item--sub-title">
                    Change the look and feel of the app by selecting a different
                    theme
                  </div>
                  <div className="settings-list-item--body settings-sublist">
                    <div
                      className="settings-sublist-item"
                      onClick={() => {
                        setModalPanel(THEMES_LIST);
                      }}
                    >
                      <div className="settings-sublist-item--heading">
                        Select Theme to use
                      </div>
                    </div>
                  </div>
                </div>
                <div className="settings-list-item">
                  <div className="settings-list-item--title">
                    SAVE / LOAD FILES FROM THIS DEVICE
                  </div>
                  <div className="settings-list-item--sub-title">
                    Save Groups / Group members to the local disk or network
                    drive
                  </div>
                  <div
                    className="settings-list-item--body settings-sublist"
                    onClick={() => {
                      setExportFileName(EXPORT_IMPORT_FILE_DEFAULT_NAME_GROUPS);
                      setShowExportConfirm(true);
                      setShowSettingsModal(false);
                      setType("groups");
                    }}
                  >
                    <div className="settings-sublist-item">
                      <div className="settings-sublist-item--heading">
                        Save Groups / Members to this device{" "}
                        <FaFileUpload className="file-upload-icon" />
                      </div>
                    </div>
                  </div>
                  <div className="hr"></div>
                  <div className="settings-list-item--body settings-sublist">
                    <div
                      className="settings-sublist-item"
                      onClick={() => {
                        inputFileRefGroups.current!.click();
                      }}
                    >
                      <div className="settings-sublist-item--heading">
                        Load Groups / Members from this device{" "}
                        <FaFileDownload className="file-download-icon" />
                      </div>
                      <input
                        type="file"
                        onChange={onFileChange}
                        style={{
                          position: "absolute",
                          top: -1000,
                          visibility: "hidden",
                        }}
                        ref={inputFileRefGroups}
                        accept=".csv"
                      />
                    </div>
                  </div>
                </div>

                <div className="settings-list-item">
                  <div className="settings-list-item--sub-title">
                    Save Events / Events teams to the local disk or network
                    drive
                  </div>
                  <div className="settings-list-item--body settings-sublist">
                    <div
                      className="settings-sublist-item"
                      onClick={() => {
                        setExportFileName(EXPORT_IMPORT_FILE_DEFAULT_NAME_EVENTS);
                        setShowExportConfirm(true);
                        setShowSettingsModal(false);
                        setType("events");
                      }}
                    >
                      <div className="settings-sublist-item--heading">
                        Save Events / Teams to this device{" "}
                        <FaFileUpload className="file-upload-icon" />
                      </div>
                    </div>
                  </div>
                  <div className="hr"></div>
                  <div className="settings-list-item--body settings-sublist">
                    <div
                      className="settings-sublist-item"
                      onClick={() => {
                        inputFileRefEvents.current!.click();
                      }}
                    >
                      <div className="settings-sublist-item--heading">
                        Load Events / Teams from this device{" "}
                        <FaFileDownload className="file-download-icon" />
                      </div>
                      <input
                        type="file"
                        onChange={onFileChangeEvents}
                        style={{
                          position: "absolute",
                          top: -1000,
                          visibility: "hidden",
                        }}
                        ref={inputFileRefEvents}
                        accept=".csv"
                      />
                    </div>
                  </div>
                </div>

                <div className="settings-list-item">
                  <div className="settings-list-item--title">
                    SAVE / LOAD FILES FROM DROPBOX{" "}
                    <FaDropbox className="dropbox-icon" />
                  </div>
                  <div className="settings-list-item--sub-title">
                    *These functions require a FREE Dropbox account. Share
                    Groups / Members with the{" "}
                    <a href={MOBILE_APP_URL}>mobile app</a>
                  </div>

                  <div className="settings-list-item--body settings-sublist">
                    <div
                      className="settings-sublist-item"
                      onClick={() => {
                        if (token) {
                          dbxDisconnect();
                        } else {
                          dbxConnect();
                        }
                      }}
                    >
                      <div className="settings-sublist-item--heading">
                        {" "}
                        Connect / Disconnect Dropbox{" "}
                        <span style={{ color: "#8f8f8f", fontWeight: 600 }}>
                          {token ? "Connected" : "Disconnected"}
                        </span>
                        <ImPower className="connection-icon" />
                      </div>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      if (token) {
                        setType("groups");
                      } else {
                        dbxConnect();
                      }
                    }}
                  >
                    <div className="hr"></div>
                    <div className="settings-list-item--body settings-sublist">
                      <div className="settings-sublist-item">
                        <div
                          className="settings-sublist-item--heading"
                          onClick={() => {
                            if (token) {
                              setFileName(EXPORT_IMPORT_FILE_DEFAULT_NAME_GROUPS);
                              setModalPanel(BACKUP_DATA);
                              dbxGetFilesList("/import");
                              setCurrentPath("/import");
                              setFileImportError([]);
                            } else {
                              dbxConnect();
                            }
                          }}
                        >
                          Save Groups / Members to Dropbox{" "}
                          <FaCloudUploadAlt className="cloud-upload-icon" />
                        </div>
                      </div>
                    </div>
                    <div className="hr"></div>
                    <div className="settings-list-item--body settings-sublist">
                      <div
                        className="settings-sublist-item"
                        onClick={() => {
                          if (token) {
                            setModalPanel(RESTORE_DATA);
                            dbxGetFilesList("/import");
                            setCurrentPath("/import");
                            setFileImportError([]);
                          } else {
                            dbxConnect();
                          }
                        }}
                      >
                        <div className="settings-sublist-item--heading">
                          Load Groups / Members from Dropbox{" "}
                          <FaCloudDownloadAlt className="cloud-download-icon" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      if (token) {
                        setType("events");
                      } else {
                        dbxConnect();
                      }
                    }}
                  >
                    <div className="hr"></div>
                    <div className="settings-list-item--body settings-sublist">
                      <div className="settings-sublist-item">
                        <div
                          className="settings-sublist-item--heading"
                          onClick={() => {
                            if (token) {
                              setFileName(EXPORT_IMPORT_FILE_DEFAULT_NAME_EVENTS);
                              setModalPanel(BACKUP_DATA);
                              dbxGetFilesList("/import Teams");
                              setCurrentPath("/import Teams");
                              setFileImportError([]);
                            } else {
                              dbxConnect();
                            }
                          }}
                        >
                          Save Events / Teams to Dropbox{" "}
                          <FaCloudUploadAlt className="cloud-upload-icon" />
                        </div>
                      </div>
                    </div>
                    <div className="hr"></div>
                    <div className="settings-list-item--body settings-sublist">
                      <div
                        className="settings-sublist-item"
                        onClick={() => {
                          if (token) {
                            setModalPanel(RESTORE_DATA);
                            dbxGetFilesList("/import Teams");
                            setCurrentPath("/import Teams");
                            setFileImportError([]);
                          } else {
                            dbxConnect();
                          }
                        }}
                      >
                        <div className="settings-sublist-item--heading">
                          Load Events / Teams from Dropbox{" "}
                          <FaCloudDownloadAlt className="cloud-download-icon" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="settings-list-item">
                  <div className="settings-list-item--title">
                    HELP AND SUPPORT
                  </div>
                  <div className="settings-list-item--body settings-sublist">
                    <div className="settings-sublist-item">
                      <div
                        className="settings-sublist-item--heading"
                        onClick={() => {
                          gotoURL(FAQ_URL, "_blank");
                        }}
                      >
                        FAQs
                      </div>
                    </div>
                  </div>
                  <div className="hr"></div>
                  <div className="settings-list-item--body settings-sublist">
                    <div className="settings-sublist-item">
                      <div className="settings-sublist-item--heading">
                        <a href="mailto:support@edtechmonster.com?subject=Feedback for Random Team Selector Pro Web App">
                          Send your feedback
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="hr"></div>
                  <div className="settings-list-item--body settings-sublist">
                    <div className="settings-sublist-item">
                      <div
                        className="settings-sublist-item--heading"
                        onClick={() => {
                          gotoURL(WEBSITE_URL, "_blank");
                        }}
                      >
                        Check out our other apps
                      </div>
                    </div>
                  </div>
                  <div className="hr"></div>
                  <div className="settings-list-item--body settings-sublist">
                    <div className="settings-sublist-item">
                      <div className="settings-sublist-item--heading">
                        <a href="mailto:support@edtechmonster.com?subject=Support Request for Random Team Selector Pro Web App">
                          Email support
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {(modalPanel === RESTORE_DATA || modalPanel === BACKUP_DATA) && (
            <div className="data-container">
              {loader && <div className="loader">Loading...</div>}
              <ul className="nav nav-pills">
                {breadCrumbs.map((folder: any) => {
                  return (
                    <li className="nav-item" key={folder.index}>
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => {
                          dbxGetFilesList(folder.path);
                          setCurrentPath(folder.path);
                        }}
                      >
                        {folder.label == "" ? "Home" : folder.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <ul className="list-group files-list">
                {filesList.map((file: any, index: number) => {
                  return (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      onClick={() => {
                        if (file[".tag"] == "folder") {
                          dbxGetFilesList(file.path_lower);
                          let _currentPath = file.path_lower;
                          setCurrentPath(_currentPath);
                        }
                      }}
                      key={file.id}
                    >
                      <span>
                        {file[".tag"] == "folder" ? (
                          <Folder style={{ width: 20, height: 20 }} />
                        ) : file[".tag"] == "file" ? (
                          <File style={{ width: 20, height: 20 }} />
                        ) : (
                          ""
                        )}
                        <span className="mx-2">{file.name}</span>
                      </span>
                      {file[".tag"] == "file" && (
                        <>
                          <span className="ms-auto me-5">{file.size} b</span>
                          {modalPanel === RESTORE_DATA && (
                            <span
                              className="badge badge-success badge-pill"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (type == "groups") {
                                  dbxDownloadFile(file);
                                } else {
                                  dbxDownloadFileEvents(file);
                                }
                              }}
                            >
                              Import
                            </span>
                          )}
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
              {fileImportError.length > 0 ? (
                <div className="w-100">
                  <div className="row">
                    <div className="col-12 p-5 text-center">
                      {fileImportError.map((error: any) => {
                        const key: string = Object.keys(error)[0];
                        const value: number = error[key];
                        return (
                          <div className="alert alert-danger">{`Line ${value}: ${key}`}</div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {dbxImportSuccess && type == "groups" && (
                <div className="w-100">
                  <div className="col-12 p-5 text-center">
                    <div>
                      <span className="text-center text-success">
                        {FILE_IMPORT_SUCCESS}
                      </span>
                      <button
                        onClick={() => {
                          setViewDetails(!viewDetails);
                        }}
                        className="btn btn-link"
                      >
                        {viewDetails ? "Hide details <<" : "Show details >>"}
                      </button>
                    </div>
                    {viewDetails && (
                      <p className="text-center">{`Imported ${importedCounts.groupsCount} groups with ${importedCounts.membersCount} members in total`}</p>
                    )}
                  </div>

                  <div
                    className="d-flex align-item-center justify-content-center pt-3"
                    style={{ marginBottom: 15 }}
                  >
                    <button
                      style={{ marginRight: 15 }}
                      className="btn btn-primary"
                      onClick={() => {
                        replaceDbxFile();
                      }}
                    >
                      Replace
                    </button>
                    <button
                      style={{ marginRight: 15 }}
                      className="btn btn-secondary"
                      onClick={() => {
                        setDbxImportSuccess(false);
                      }}
                    >
                      Discard
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        mergeDbxFile();
                      }}
                    >
                      Merge
                    </button>
                  </div>
                </div>
              )}
              {dbxImportSuccess && type == "events" && (
                <div className="w-100">
                  <div className="col-12 p-5 text-center">
                    <div>
                      <span className="text-center text-success">
                        {FILE_IMPORT_SUCCESS}
                      </span>
                      <button
                        onClick={() => {
                          setViewDetails(!viewDetails);
                        }}
                        className="btn btn-link"
                      >
                        {viewDetails ? "Hide details <<" : "Show details >>"}
                      </button>
                    </div>
                    {viewDetails && (
                      <p className="text-center">{`Imported ${importedCountsEvent.eventsCount} groups with ${importedCountsEvent.teamsCount} members in total`}</p>
                    )}
                  </div>

                  <div
                    className="d-flex align-item-center justify-content-center pt-3"
                    style={{ marginBottom: 15 }}
                  >
                    <button
                      style={{ marginRight: 15 }}
                      className="btn btn-primary"
                      onClick={() => {
                        replaceDbxFileEvents();
                      }}
                    >
                      Replace
                    </button>
                    <button
                      style={{ marginRight: 15 }}
                      className="btn btn-secondary"
                      onClick={() => {
                        setDbxImportSuccess(false);
                      }}
                    >
                      Discard
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        mergeDbxFileEvents();
                      }}
                    >
                      Merge
                    </button>
                  </div>
                </div>
              )}

              {modalPanel === BACKUP_DATA && type == "groups" && (
                <div className="w-100">
                  <div className="form-group d-flex flex-row p-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter File name"
                      value={fileName}
                      onChange={(e) => {
                        setFileName(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => {
                        if (fileName) {
                          dbxUpload(currentPath, fileName);
                        } else {
                          setShowMessageModal(true);
                          setModalMessage(FILENAME_EMPTY_ERROR);
                        }
                      }}
                    >
                      Save File
                    </button>
                  </div>
                  <div className="form-group d-flex flex-row p-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Folder name"
                      value={folderName}
                      onChange={(e) => {
                        setFolderName(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => {
                        if (folderName) {
                          dbxCreateFolder(currentPath, folderName);
                        } else {
                          setShowMessageModal(true);
                          setModalMessage(FOLDER_NAME_EMPTY_ERROR);
                        }
                      }}
                    >
                      Create Folder
                    </button>
                  </div>
                </div>
              )}

              {modalPanel === BACKUP_DATA && type == "events" && (
                <div className="w-100">
                  <div className="form-group d-flex flex-row p-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter File name"
                      value={fileName}
                      onChange={(e) => {
                        setFileName(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => {
                        if (fileName) {
                          dbxUploadEvents(currentPath, fileName);
                        } else {
                          setShowMessageModal(true);
                          setModalMessage(FILENAME_EMPTY_ERROR);
                        }
                      }}
                    >
                      Save File
                    </button>
                  </div>
                  <div className="form-group d-flex flex-row p-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Folder name"
                      value={folderName}
                      onChange={(e) => {
                        setFolderName(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => {
                        if (folderName) {
                          dbxCreateFolder(currentPath, folderName);
                        } else {
                          setShowMessageModal(true);
                          setModalMessage(FOLDER_NAME_EMPTY_ERROR);
                        }
                      }}
                    >
                      Create Folder
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {modalPanel === THEMES_LIST && (
            <div className="themes-list">
              {themesList.map((theme: any) => {
                return (
                  <div
                    key={theme.id}
                    className={`themes-list-item ${theme.name} ${theme.name === selectedTheme.name ? "selected" : ""
                      }`}
                    onClick={() => {
                      bgSoundHandler(STOP);
                      setDisplayingMember(null);
                      dispatch(setSelectedTheme(theme));
                    }}
                  ></div>
                );
              })}
            </div>
          )}
          {modalPanel === EXPORT_FILE && (
            <div>
              {fileImportStatus === FILE_IMPORT_STATUS.PARTIAL_SUCCESS &&
                type == "groups" &&
                (fileImportError.length > 0 ? (
                  <div className="row">
                    <div className="col-12 p-5 text-center">
                      <div>
                        <span className="text-center text-info">
                          {FILE_IMPORT_PARTIAL_SUCCESS}
                        </span>
                        <button
                          onClick={() => {
                            setViewDetails(!viewDetails);
                          }}
                          className="btn btn-link"
                        >
                          {viewDetails ? "Hide details <<" : "Show details >>"}
                        </button>
                      </div>
                      {viewDetails && (
                        <ul className="list-group">
                          <li className="list-group-item list-group-item-info">{`Imported ${importedCounts.groupsCount} groups with ${importedCounts.membersCount} members in total`}</li>
                          {fileImportError.map((error: any, index: number) => {
                            const key: string = Object.keys(error)[0];
                            const value: number = error[key];
                            return (
                              <li
                                className="list-group-item list-group-item-danger"
                                key={index}
                              >
                                Error {`${key}`} in line {value}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {renderFileControls()}
                    </div>
                  </div>
                ) : (
                  ""
                ))}
              {fileImportStatus === FILE_IMPORT_STATUS.PARTIAL_SUCCESS &&
                type == "events" &&
                (fileImportError.length > 0 ? (
                  <div className="row">
                    <div className="col-12 p-5 text-center">
                      <div>
                        <span className="text-center text-info">
                          {FILE_IMPORT_PARTIAL_SUCCESS}
                        </span>
                        <button
                          onClick={() => {
                            setViewDetails(!viewDetails);
                          }}
                          className="btn btn-link"
                        >
                          {viewDetails ? "Hide details <<" : "Show details >>"}
                        </button>
                      </div>
                      {viewDetails && (
                        <ul className="list-group">
                          <li className="list-group-item list-group-item-info">{`Imported ${importedCountsEvent.eventsCount} groups with ${importedCountsEvent.teamsCount} members in total`}</li>
                          {fileImportError.map((error: any, index: number) => {
                            const key: string = Object.keys(error)[0];
                            const value: number = error[key];
                            return (
                              <li
                                className="list-group-item list-group-item-danger"
                                key={index}
                              >
                                Error {`${key}`} in line {value}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {renderFileControlsEvents()}
                    </div>
                  </div>
                ) : (
                  ""
                ))}
              {fileImportStatus === FILE_IMPORT_STATUS.FAILURE &&
                (fileImportError.length > 0 ? (
                  <div className="row">
                    <div className="col-12 p-5 text-center">
                      <div>
                        <span className="text-center text-danger">
                          {FILE_IMPORT_FAILED}
                        </span>
                        <button
                          onClick={() => {
                            setViewDetails(!viewDetails);
                          }}
                          className="btn btn-link"
                        >
                          {viewDetails ? "Hide details <<" : "Show details >>"}
                        </button>
                      </div>
                      {viewDetails && (
                        <ul className="list-group">
                          {fileImportError.map((error: any, index: number) => {
                            const key: string = Object.keys(error)[0];
                            const value: number = error[key];
                            return (
                              <li
                                className="list-group-item list-group-item-danger"
                                key={index}
                              >
                                Error {`${key}`} in line {value}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                ))}
              {fileImportStatus === FILE_IMPORT_STATUS.SUCCESS &&
                type == "groups" && (
                  <div className="row">
                    <div className="col-12 p-5 text-center">
                      <div>
                        <span className="text-center text-success">
                          {FILE_IMPORT_SUCCESS}
                        </span>
                        <button
                          onClick={() => {
                            setViewDetails(!viewDetails);
                          }}
                          className="btn btn-link"
                        >
                          {viewDetails ? "Hide details <<" : "Show details >>"}
                        </button>
                      </div>
                      {viewDetails && (
                        <ul className="list-group">
                          <li className="list-group-item list-group-item-success">{`Imported ${importedCounts.groupsCount} groups with ${importedCounts.membersCount} members in total`}</li>
                        </ul>
                      )}
                      {renderFileControls()}
                    </div>
                  </div>
                )}
              {fileImportStatus === FILE_IMPORT_STATUS.SUCCESS &&
                type == "events" && (
                  <div className="row">
                    <div className="col-12 p-5 text-center">
                      <div>
                        <span className="text-center text-success">
                          {FILE_IMPORT_SUCCESS}
                        </span>
                        <button
                          onClick={() => {
                            setViewDetails(!viewDetails);
                          }}
                          className="btn btn-link"
                        >
                          {viewDetails ? "Hide details <<" : "Show details >>"}
                        </button>
                      </div>
                      {viewDetails && (
                        <ul className="list-group">
                          <li className="list-group-item list-group-item-success">{`Imported ${importedCountsEvent.eventsCount} groups with ${importedCountsEvent.teamsCount} members in total`}</li>
                        </ul>
                      )}
                      {renderFileControlsEvents()}
                    </div>
                  </div>
                )}
            </div>
          )}
          {modalPanel === EXPORT_FILE_LOAD && type == "groups" && (
            <>
              <div className="container">
                <div className="row">
                  <div className="form-group clearfix">
                    <p>
                      Data has been imported{" "}
                      <strong className="text-success">successfully</strong>
                    </p>
                    {!viewDetails ? (
                      <a
                        href="#"
                        onClick={() => {
                          setViewDetails(true);
                        }}
                      >
                        View Details
                      </a>
                    ) : (
                      <a
                        href="#"
                        onClick={() => {
                          setViewDetails(false);
                        }}
                      >
                        Hide Details
                      </a>
                    )}
                  </div>
                </div>
              </div>
              {viewDetails && (
                <div className="alert alert-success" role="alert">
                  Imported {importedCounts.groupsCount} group
                  {importedCounts.groupsCount > 1 ? "s" : ""} with{" "}
                  {importedCounts.membersCount} member
                  {importedCounts.membersCount > 1 ? "s" : ""} in total
                </div>
              )}
            </>
          )}
          {modalPanel === EXPORT_FILE_LOAD && type == "events" && (
            <>
              <div className="container">
                <div className="row">
                  <div className="form-group clearfix">
                    <p>
                      Data has been imported{" "}
                      <strong className="text-success">successfully</strong>
                    </p>
                    {!viewDetails ? (
                      <a
                        href="#"
                        onClick={() => {
                          setViewDetails(true);
                        }}
                      >
                        View Details
                      </a>
                    ) : (
                      <a
                        href="#"
                        onClick={() => {
                          setViewDetails(false);
                        }}
                      >
                        Hide Details
                      </a>
                    )}
                  </div>
                </div>
              </div>
              {viewDetails && (
                <div className="alert alert-success" role="alert">
                  Imported {importedCountsEvent.eventsCount} Events
                  {importedCountsEvent.eventsCount > 1 ? "s" : ""} with{" "}
                  {importedCountsEvent.teamsCount} Teams
                  {importedCountsEvent.teamsCount > 1 ? "s" : ""} in total
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center"></Modal.Footer>
      </Modal>

      <Modal show={showExportConfirm} backdrop="static" centered>
        <Modal.Header closeButton={false}>
          <Modal.Title>Export File</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>File will be downloaded to your browser Downloads directory</p>
          <div className="form-group">
            <label htmlFor="exportFileName">File Name</label>
            <input
              type="text"
              className="form-control"
              id="exportFileName"
              value={exportFileName}
              onChange={(e) => {
                setExportFileName(e.target.value);
              }}
              placeholder="Enter csv file name"
            />
            <small id="exportHelp" className="form-text text-danger">
              {exportFileName.trim().length === 0 &&
                `file name can not be empty`}{" "}
            </small>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowExportConfirm(false);
              setExportFileName(EXPORT_IMPORT_FILE_DEFAULT_NAME);
              setShowSettingsModal(true);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={exportFileName.trim().length === 0}
            onClick={() => {
              setShowExportConfirm(false);
              if (type == "groups") {
                generateCSVFile(groups);
              } else {
                generateCSVFileEvents(events);
              }
              setShowSettingsModal(true);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        showHideModal={setShowMessageModal}
      ></MessageModal>
    </>
  );
};

export default Main;
