import React, { useCallback, useEffect, useState } from "react";
import "../App.scss";
import Group from "../components/Group";
import Member from "../components/Member";
import { ReactComponent as AddGroup } from "../assets/controls/Add-Group.svg";
import { ReactComponent as AddMember } from "../assets/controls/Add-Member.svg";
import RNSModal from "../components/RNSModal";
import Switch from "../components/Switch";
import { useDispatch, useSelector } from "react-redux";
import { GroupState } from "../store/types/group";
import { setGroups, setSelectedGroupIndex } from "../store/actions/group";
import { CommonState } from "../store/types/common";
import { setMaxShowCount } from "../store/actions/common";
import MessageModal from "../components/MessageModal";
import {
  GROUP_MEMBER,
  GROUP_NAME,
  MAX_ACTIVE_MEMBERS_COUNT,
  MAX_NAME_LENGTH,
  MEMBERS_ADDED_AND_COUNT_EXCEEDED,
  MEMBERS_COUNT_EXCEEDED,
  MEMBERS_COUNT_LOW,
  MIN_ACTIVE_MEMBERS_COUNT,
  RNS_MODAL_GROUP_MEMBERS_BODY_TEXT,
  RNS_MODAL_GROUP_NAME_BODY_TEXT,
  UNEQUAL_MEMBERS,
} from "../utils/constants";
import { EventState } from "../store/types/event";
type GroupsProps = {
  gotoScreen?: (val: string) => void;
};
const Groups: React.FC<GroupsProps> = ({ gotoScreen = () => {} }) => {
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [editGroups, setEditGroups] = useState(false);
  const [editMembers, setEditMembers] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [tempMembers, setTempMembers] = useState<Array<any>>([]);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const { groups, selectedGroupIndex } = useSelector(
    (state: { group: GroupState }) => state.group
  );
  const { events, selectedEventIndex } = useSelector(
    (state: { event: EventState }) => state.event
  );

  const gotoMain = () => {
    gotoScreen("main");
  };

  const isMemberCountMax = () => {
    const activeMembersCount = groups[selectedGroupIndex].members.filter(
      (member: any) => member.selected
    ).length;
    return activeMembersCount >= MAX_ACTIVE_MEMBERS_COUNT;
  };

  const isMemberCountMin = () => {
    const activeMembersCount = groups[selectedGroupIndex].members.filter(
      (member: any) => member.selected
    ).length;
    return activeMembersCount <= MIN_ACTIVE_MEMBERS_COUNT;
  };

  const handleChange = (checked: boolean, index: number) => {
    if (isMemberCountMax() && checked) {
      setModalMessage(MEMBERS_COUNT_EXCEEDED);
      setShowMessageModal(true);
    } else if (isMemberCountMin() && !checked) {
      setModalMessage(MEMBERS_COUNT_LOW);
      setShowMessageModal(true);
    } else {
      groups[selectedGroupIndex].members[index].selected = checked;
      dispatch(setGroups([...groups]));
    }
  };

  const addGroup = (groupName: string) => {
    if (groupName !== "") {
      let namesArr: Array<string> = groupName.split(",");
      namesArr.forEach((name: string) => {
        name = name.trim();
        if (name && name !== "") {
          let obj = {
            name: name.trim(),
            members: [],
            maxShowCount: 1,
          };
          groups.push(obj);
        }
      });
    }
    dispatch(setGroups([...groups]));
    if (groups.length > 0) {
      //select last group in list
      dispatch(setSelectedGroupIndex(groups.length - 1));
    }
  };

  const addMember = (memberName: string) => {
    let _members = [...groups[selectedGroupIndex].members];
    if (memberName !== "") {
      let namesArr: Array<string> = memberName.split(",");
      namesArr.forEach((name: string) => {
        name = name.trim();
        if (name && name !== "") {
          let obj = {
            name: name.trim(),
            selected: true,
          };
          _members.push(obj);
        }
      });
      if (_members.length > MAX_ACTIVE_MEMBERS_COUNT) {
        setModalMessage(MEMBERS_ADDED_AND_COUNT_EXCEEDED);
        setShowMessageModal(true);
        setTempMembers(_members.slice(0, MAX_ACTIVE_MEMBERS_COUNT));
      } else {
        groups[selectedGroupIndex].members = _members;
        dispatch(setGroups([...groups]));
      }
    }
  };

  const teamHasUnEqualMembers = () => {
    const activeTeamsCount = events[selectedEventIndex]?.teams.filter(
      (team: any) => team.selected
    ).length;
    const activeMembersCount = groups[selectedGroupIndex]?.members.filter(
      (member: any) => member.selected
    ).length;
    return activeTeamsCount > 0 && activeMembersCount % activeTeamsCount != 0;
  };

  const teamHasLessMembers = () => {
    const activeTeamsCount = events[selectedEventIndex]?.teams.filter(
      (team: any) => team.selected
    ).length;
    const activeMembersCount = groups[selectedGroupIndex]?.members.filter(
      (member: any) => member.selected
    ).length;
    return activeMembersCount < activeTeamsCount;
  };

  useEffect(() => {
    const activeMembersCount = groups[selectedGroupIndex]?.members?.filter(
      (member: any) => member.selected
    ).length;
    if (activeMembersCount < MIN_ACTIVE_MEMBERS_COUNT) {
      setModalMessage(MEMBERS_COUNT_LOW);
      setShowMessageModal(true);
    }
  }, [
    selectedEventIndex,
    selectedGroupIndex,
    groups[selectedGroupIndex]?.members.filter((member: any) => member.selected)
      .length,
  ]);

  const onMsgModalClose = () => {
    if (
      modalMessage == MEMBERS_COUNT_EXCEEDED ||
      modalMessage == MEMBERS_COUNT_LOW
    ) {
      //Do nothing
    } else if (modalMessage == MEMBERS_ADDED_AND_COUNT_EXCEEDED) {
      groups[selectedGroupIndex].members = tempMembers;
      dispatch(setGroups([...groups]));
      setTempMembers([]);
    } else {
      gotoMain();
    }
  };

  return (
    <div className="groups-screen container-fluid theme-0 d-flex flex-column justify-content-start">
      <div className="row">
        <div className="col-12">
          <button
            className="button-back"
            onClick={() => {
              if (teamHasLessMembers()) {
                const activeTeamsCount = events[
                  selectedEventIndex
                ]?.teams.filter((team: any) => team.selected).length;
                const modalMessage = `You have ${activeTeamsCount} teams active, so you'll need to have at least ${activeTeamsCount} members active for them to be displayed correctly`;
                setModalMessage(modalMessage);
                setShowMessageModal(true);
              } else if (teamHasUnEqualMembers()) {
                const modalMessage = UNEQUAL_MEMBERS;
                setShowMessageModal(true);
                setModalMessage(modalMessage);
              } else {
                gotoMain();
              }
            }}
          >
            &#8249;Done
          </button>
        </div>
      </div>
      <div className="row flex-grow-1">
        <div className="col-4 section-left d-flex flex-column">
          <h3>
            Groups
            {!editGroups && groups.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditGroups(true);
                }}
              >
                Edit
              </button>
            )}
            {editGroups && groups.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditGroups(false);
                }}
              >
                Done Editing
              </button>
            )}
          </h3>
          {groups.length > 0 && (
            <div className="label-active-total">Active/Total</div>
          )}

          <div className="groups-list flex-grow-1">
            {groups.map((group: any, index: number) => {
              return (
                <Group
                  index={index}
                  key={index}
                  name={group.name}
                  edit={editGroups}
                  members={group.members}
                  selectedGroupIndex={selectedGroupIndex}
                  setEditGroup={(groupName: string, index: number) => {
                    groups[index].name = groupName;
                    dispatch(setGroups(groups));
                  }}
                  setDeleteGroup={(index: number) => {
                    groups.splice(index, 1);
                    if (selectedGroupIndex == index) {
                      dispatch(setSelectedGroupIndex(0));
                    }
                    dispatch(setGroups(groups));
                  }}
                  setSelectedGroupIndex={(index) => {
                    dispatch(setSelectedGroupIndex(index));
                  }}
                ></Group>
              );
            })}
          </div>
        </div>
        <div className="col-8 section-right d-flex flex-column">
          <h3>
            {groups[selectedGroupIndex]?.name}
            {!editMembers && groups[selectedGroupIndex]?.members.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditMembers(true);
                }}
              >
                Edit
              </button>
            )}
            {editMembers && groups[selectedGroupIndex]?.members.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditMembers(false);
                }}
              >
                Done Editing
              </button>
            )}
          </h3>
          <div className="members-list flex-grow-1">
            {groups[selectedGroupIndex]?.members.map(
              (member: any, index: number) => {
                return (
                  <Member
                    key={index}
                    name={member.name}
                    index={index}
                    selected={member.selected}
                    edit={editMembers}
                    handleChange={handleChange}
                    setEditMember={(memberName: string, index: number) => {
                      groups[selectedGroupIndex].members[index].name =
                        memberName;
                      dispatch(setGroups([...groups]));
                    }}
                    setDeleteMember={(index: number) => {
                      groups[selectedGroupIndex].members.splice(index, 1);
                      dispatch(setGroups([...groups]));
                    }}
                  ></Member>
                );
              }
            )}
          </div>
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col-4" style={{ borderRight: "1px solid #ccc" }}>
          <div className="footer py-3" style={{ textAlign: "right" }}>
            <button
              className="button-add"
              onClick={() => {
                setShowGroupModal(true);
              }}
            >
              <AddGroup></AddGroup>Add Group
            </button>
          </div>
        </div>
        <div className="col-8">
          <div className="footer py-3">
            {groups.length > 0 && (
              <button
                className="button-add"
                onClick={() => {
                  setShowMemberModal(true);
                }}
              >
                <AddMember></AddMember>Add Member
              </button>
            )}
          </div>
        </div>
      </div>
      <RNSModal
        show={showGroupModal}
        title={GROUP_NAME}
        body={`${RNS_MODAL_GROUP_NAME_BODY_TEXT} ${MAX_NAME_LENGTH}`}
        addName={addGroup}
        showHideModal={setShowGroupModal}
      ></RNSModal>
      <RNSModal
        show={showMemberModal}
        title={GROUP_MEMBER}
        body={`${RNS_MODAL_GROUP_MEMBERS_BODY_TEXT} ${MAX_NAME_LENGTH}`}
        addName={addMember}
        showHideModal={setShowMemberModal}
      ></RNSModal>
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        showHideModal={setShowMessageModal}
        onClose={onMsgModalClose}
      ></MessageModal>
    </div>
  );
};

export default Groups;
