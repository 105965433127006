import React, { useCallback, useEffect, useState } from "react";
import "../App.scss";
import Event from "../components/Event";
import Team from "../components/Team";
import { ReactComponent as AddEvent } from "../assets/controls/Add-Event-Blue.svg";
import { ReactComponent as AddTeam } from "../assets/controls/Add-Team-Blue.svg";
import RNSModal from "../components/RNSModal";
import Switch from "../components/Switch";
import { useDispatch, useSelector } from "react-redux";
import { EventState } from "../store/types/event";
import { setEvents, setSelectedEventIndex } from "../store/actions/event";
import { CommonState } from "../store/types/common";
import { setMaxShowCount } from "../store/actions/common";
import MessageModal from "../components/MessageModal";
import {
  EVENT_TEAM,
  EVENT_NAME,
  MAX_NAME_LENGTH,
  RNS_MODAL_EVENT_TEAMS_BODY_TEXT,
  RNS_MODAL_EVENT_NAME_BODY_TEXT,
  UNEQUAL_MEMBERS,
  MAX_ACTIVE_TEAMS_COUNT,
  TEAMS_COUNT_EXCEEDED,
  TEAMS_ADDED_AND_COUNT_EXCEEDED,
  MIN_ACTIVE_TEAMS_COUNT,
  TEAMS_COUNT_LOW,
} from "../utils/constants";
import { GroupState } from "../store/types/group";
type EventsProps = {
  gotoScreen?: (val: string) => void;
};
const Events: React.FC<EventsProps> = ({ gotoScreen = () => {} }) => {
  const [showEventModal, setShowEventModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [editEvents, setEditEvents] = useState(false);
  const [editTeams, setEditTeams] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [tempTeams, setTempTeams] = useState<Array<any>>([]);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const { events, selectedEventIndex } = useSelector(
    (state: { event: EventState }) => state.event
  );
  const { groups, selectedGroupIndex } = useSelector(
    (state: { group: GroupState }) => state.group
  );

  const gotoMain = () => {
    gotoScreen("main");
  };

  const handleChange = (checked: boolean, index: number) => {
    if (isTeamCountMax() && checked) {
      setModalMessage(TEAMS_COUNT_EXCEEDED);
      setShowMessageModal(true);
    } else if (isTeamCountMin() && !checked) {
      setModalMessage(TEAMS_COUNT_LOW);
      setShowMessageModal(true);
    } else {
      events[selectedEventIndex].teams[index].selected = checked;
      dispatch(setEvents([...events]));
    }
  };

  const addEvent = (eventName: string) => {
    if (eventName !== "") {
      let namesArr: Array<string> = eventName.split(",");
      namesArr.forEach((name: string) => {
        name = name.trim();
        if (name && name !== "") {
          let obj = {
            name: name.trim(),
            teams: [],
            maxShowCount: 1,
          };
          events.push(obj);
        }
      });
    }
    dispatch(setEvents([...events]));
    if (events.length > 0) {
      //select last event in list
      dispatch(setSelectedEventIndex(events.length - 1));
    }
  };

  const addTeam = (teamName: string) => {
    let _teams = [...events[selectedEventIndex].teams];
    if (teamName !== "") {
      let namesArr: Array<string> = teamName.split(",");
      namesArr.forEach((name: string) => {
        name = name.trim();
        const selected =
          _teams.filter((team: any) => team.selected).length <
          MAX_ACTIVE_TEAMS_COUNT;
        if (name && name !== "") {
          let obj = {
            name: name.trim(),
            selected: selected,
          };
          _teams.push(obj);
        }
      });

      if (
        _teams.filter((team: any) => team.selected).length >=
          MAX_ACTIVE_TEAMS_COUNT &&
        _teams.length > MAX_ACTIVE_TEAMS_COUNT
      ) {
        setModalMessage(TEAMS_ADDED_AND_COUNT_EXCEEDED);
        setShowMessageModal(true);
        setTempTeams(_teams);
      } else {
        events[selectedEventIndex].teams = _teams;
        dispatch(setEvents([...events]));
      }
    }
  };

  const isTeamCountMax = () => {
    const activeTeamsCount = events[selectedEventIndex].teams.filter(
      (team: any) => team.selected
    ).length;
    return activeTeamsCount >= MAX_ACTIVE_TEAMS_COUNT;
  };

  const isTeamCountMin = () => {
    const activeTeamsCount = events[selectedEventIndex].teams.filter(
      (team: any) => team.selected
    ).length;
    return activeTeamsCount <= MIN_ACTIVE_TEAMS_COUNT;
  };

  const teamHasUnEqualMembers = () => {
    const activeTeamsCount = events[selectedEventIndex]?.teams.filter(
      (team: any) => team.selected
    ).length;
    const activeMembersCount = groups[selectedGroupIndex]?.members.filter(
      (member: any) => member.selected
    ).length;
    return activeTeamsCount > 0 && activeMembersCount % activeTeamsCount != 0;
  };

  const teamHasLessMembers = () => {
    const activeTeamsCount = events[selectedEventIndex]?.teams.filter(
      (team: any) => team.selected
    ).length;
    const activeMembersCount = groups[selectedGroupIndex]?.members.filter(
      (member: any) => member.selected
    ).length;
    return activeMembersCount < activeTeamsCount;
  };

  useEffect(() => {
    const activeTeamsCount = events[selectedEventIndex]?.teams.filter(
      (team: any) => team.selected
    ).length;
    if (activeTeamsCount < MIN_ACTIVE_TEAMS_COUNT) {
      setModalMessage(TEAMS_COUNT_LOW);
      setShowMessageModal(true);
    }
  }, [
    selectedEventIndex,
    selectedGroupIndex,
    groups[selectedGroupIndex]?.members.filter((member: any) => member.selected)
      .length,
  ]);

  const onMsgModalClose = () => {
    if (
      modalMessage == TEAMS_COUNT_EXCEEDED ||
      modalMessage == TEAMS_COUNT_LOW
    ) {
      //Do nothing
    } else if (modalMessage == TEAMS_ADDED_AND_COUNT_EXCEEDED) {
      events[selectedEventIndex].teams = tempTeams;
      dispatch(setEvents([...events]));
      setTempTeams([]);
    } else {
      gotoMain();
    }
  };

  return (
    <div className="groups-screen container-fluid theme-0 d-flex flex-column justify-content-start">
      <div className="row">
        <div className="col-12">
          <button
            className="button-back"
            onClick={() => {
              if (teamHasLessMembers()) {
                const activeTeamsCount = events[
                  selectedEventIndex
                ]?.teams.filter((team: any) => team.selected).length;
                const modalMessage = `You have ${activeTeamsCount} teams active, so you'll need to have at least ${activeTeamsCount} members active for them to be displayed correctly`;
                setModalMessage(modalMessage);
                setShowMessageModal(true);
              } else if (teamHasUnEqualMembers()) {
                const modalMessage = UNEQUAL_MEMBERS;
                setShowMessageModal(true);
                setModalMessage(modalMessage);
              } else {
                gotoMain();
              }
            }}
          >
            &#8249;Done
          </button>
        </div>
      </div>
      <div className="row flex-grow-1">
        <div className="col-4 section-left d-flex flex-column">
          <h3>
            Events
            {!editEvents && events.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditEvents(true);
                }}
              >
                Edit
              </button>
            )}
            {editEvents && events.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditEvents(false);
                }}
              >
                Done Editing
              </button>
            )}
          </h3>
          {events.length > 0 && (
            <div className="label-active-total">Active/Total</div>
          )}
          <div className="groups-list flex-grow-1">
            {events.map((event: any, index: number) => {
              return (
                <Event
                  index={index}
                  key={index}
                  name={event.name}
                  edit={editEvents}
                  teams={event.teams}
                  selectedEventIndex={selectedEventIndex}
                  setEditEvent={(eventName: string, index: number) => {
                    events[index].name = eventName;
                    dispatch(setEvents(events));
                  }}
                  setDeleteEvent={(index: number) => {
                    events.splice(index, 1);
                    if (selectedEventIndex == index) {
                      dispatch(setSelectedEventIndex(0));
                    }
                    dispatch(setEvents(events));
                  }}
                  setSelectedEventIndex={(index) => {
                    dispatch(setSelectedEventIndex(index));
                  }}
                ></Event>
              );
            })}
          </div>
        </div>
        <div className="col-8 section-right d-flex flex-column">
          <h3>
            {events[selectedEventIndex]?.name}
            {!editTeams && events[selectedEventIndex]?.teams.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditTeams(true);
                }}
              >
                Edit
              </button>
            )}
            {editTeams && events[selectedEventIndex]?.teams.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditTeams(false);
                }}
              >
                Done Editing
              </button>
            )}
          </h3>
          <div className="members-list flex-grow-1">
            {events[selectedEventIndex]?.teams.map(
              (team: any, index: number) => {
                return (
                  <Team
                    key={index}
                    name={team.name}
                    index={index}
                    selected={team.selected}
                    edit={editTeams}
                    handleChange={handleChange}
                    setEditTeam={(teamName: string, index: number) => {
                      events[selectedEventIndex].teams[index].name = teamName;
                      dispatch(setEvents([...events]));
                    }}
                    setDeleteTeam={(index: number) => {
                      events[selectedEventIndex].teams.splice(index, 1);
                      dispatch(setEvents([...events]));
                    }}
                  ></Team>
                );
              }
            )}
          </div>
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col-4" style={{ borderRight: "1px solid #ccc" }}>
          <div className="footer py-3" style={{ textAlign: "right" }}>
            <button
              className="button-add"
              onClick={() => {
                setShowEventModal(true);
              }}
            >
              <AddEvent></AddEvent>Add Event
            </button>
          </div>
        </div>
        <div className="col-8">
          <div className="footer py-3">
            {events.length > 0 && (
              <button
                className="button-add"
                onClick={() => {
                  setShowTeamModal(true);
                }}
              >
                <AddTeam></AddTeam>Add Team
              </button>
            )}
          </div>
        </div>
      </div>
      <RNSModal
        show={showEventModal}
        title={EVENT_NAME}
        body={`${RNS_MODAL_EVENT_NAME_BODY_TEXT} ${MAX_NAME_LENGTH}`}
        addName={addEvent}
        showHideModal={setShowEventModal}
      ></RNSModal>
      <RNSModal
        show={showTeamModal}
        title={EVENT_TEAM}
        body={`${RNS_MODAL_EVENT_TEAMS_BODY_TEXT} ${MAX_NAME_LENGTH}`}
        addName={addTeam}
        showHideModal={setShowTeamModal}
      ></RNSModal>
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        showHideModal={setShowMessageModal}
        onClose={onMsgModalClose}
      ></MessageModal>
    </div>
  );
};

export default Events;
