import {
  SetEvents,
  SetSelectedEventIndex,
  SET_EVENTS,
  SET_SELECTED_EVENT_INDEX,
} from "../types/event";

export const setEvents = (payload: any[]): SetEvents => ({
  type: SET_EVENTS,
  payload,
});

export const setSelectedEventIndex = (
  payload: number
): SetSelectedEventIndex => ({
  type: SET_SELECTED_EVENT_INDEX,
  payload,
});
