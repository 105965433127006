import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../App.scss";
import { GroupState } from "../store/types/group";
import {
  DELETE_GROUP,
  EDIT_GROUP_NAME,
  MAX_NAME_LENGTH,
} from "../utils/constants";
type GroupProps = {
  name: string;
  edit: boolean;
  members: Array<any>;
  index: number;
  selectedGroupIndex: number;
  setSelectedGroupIndex?: (index: number) => void;
  setEditGroup?: (groupName: string, index: number) => void;
  setDeleteGroup?: (index: number) => void;
};
const Group: React.FC<GroupProps> = ({
  name,
  edit,
  members,
  index,
  selectedGroupIndex,
  setSelectedGroupIndex = (index: number) => {},
  setEditGroup = (groupName: string, index: number) => {},
  setDeleteGroup = (index: number) => {},
}) => {
  const [editShow, setEditShow] = useState(false);
  const [editGroupValue, setEditGroupValue] = useState<string>("");
  const [deleteGroupValue, setDeleteGroupValue] = useState<string>("");
  const [duplicateNameError, setDuplicateNameError] = useState<string>("");
  const [deleteShow, setDeleteShow] = useState(false);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { groups } = useSelector((state: { group: GroupState }) => state.group);

  const saveEditGroup = (name: string, index: number) => {
    name = name.trim();
    setEditGroup(name, index);
    setEditShow(false);
  };

  const checkGroupExists = (name: string, ignoreName: string) =>
    !!groups.find(
      (group: any) => group.name !== ignoreName && group.name === name
    );

  const validate = (name: string) => {
    name = name.trim();
    const selectedGroupName: string = groups[selectedGroupIndex].name;
    if (checkGroupExists(name, selectedGroupName)) {
      setDuplicateNameError(name);
    } else {
      setDuplicateNameError("");
    }
  };

  const allowSave = () =>
    !!editGroupValue.trim().length && duplicateNameError === "";

  useEffect(() => {
    if (editShow) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [editShow]);

  return (
    <>
      <div
        className={`groups-list-item ${
          index == selectedGroupIndex ? "active" : ""
        }`}
        key={index}
        onClick={() => {
          setSelectedGroupIndex(index);
        }}
      >
        <span className="name">{name}</span>
        {edit && (
          <span className="ms-auto me-3 d-flex">
            <span
              className="badge badge-secondary badge-pill"
              onClick={() => {
                setEditShow(true);
                setEditGroupValue(name);
              }}
            >
              Edit
            </span>
            <span
              className="badge badge-danger badge-pill"
              style={{ marginLeft: 3 }}
              onClick={() => {
                setDeleteShow(true);
                setDeleteGroupValue(name);
              }}
            >
              Delete
            </span>
          </span>
        )}
        <span className="text-end members-count">
          {members.filter((member) => member.selected).length}/{members.length}
        </span>
      </div>
      <Modal
        show={editShow}
        backdrop="static"
        onHide={() => {
          setEditShow(false);
        }}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>{EDIT_GROUP_NAME}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={editGroupValue}
              maxLength={MAX_NAME_LENGTH}
              onKeyUp={(e) => {
                if (e.key === "Enter" && allowSave()) {
                  saveEditGroup(editGroupValue, selectedGroupIndex);
                }
              }}
              onChange={(e) => {
                setEditGroupValue(e.target.value);
                validate(e.target.value);
              }}
              placeholder="Group name"
              ref={inputRef}
            />
          </div>
          {duplicateNameError && (
            <span className="error">{`Group with the name ${duplicateNameError} already exists`}</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setEditShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!allowSave()}
            onClick={() => {
              saveEditGroup(editGroupValue, selectedGroupIndex);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deleteShow}
        backdrop="static"
        onHide={() => {
          setDeleteShow(false);
        }}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>{DELETE_GROUP}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure to delete group <b>{deleteGroupValue}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setDeleteGroup(selectedGroupIndex);
              setDeleteShow(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Group;
