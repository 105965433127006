import React, { useEffect, useRef, useState } from "react";
import "../App.scss";
import Switch from "./Switch";
import { Button, Modal } from "react-bootstrap";
import {
  DELETE_TEAM,
  EDIT_TEAM_NAME,
  MAX_NAME_LENGTH,
  MIN_ACTIVE_TEAMS_COUNT,
  TEAMS_COUNT_LOW,
} from "../utils/constants";
import { GroupState } from "../store/types/group";
import { useSelector } from "react-redux";
import { EventState } from "../store/types/event";
import MessageModal from "./MessageModal";
type TeamProps = {
  name: string;
  edit: boolean;
  selected: boolean;
  index: number;
  handleChange?: (checked: boolean, index: number) => void;
  setEditTeam?: (memberName: string, index: number) => void;
  setDeleteTeam?: (index: number) => void;
};
const Team: React.FC<TeamProps> = ({
  name,
  edit,
  selected,
  index,
  handleChange = (checked: boolean, index: number) => {},
  setEditTeam = (memberName: string, index: number) => {},
  setDeleteTeam = (index: number) => {},
}) => {
  const [editShow, setEditShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [editTeamValue, setEditTeamValue] = useState("");
  const [deleteTeamValue, setDeleteTeamValue] = useState("");
  const [selectedTeamName, setSelectedTeamName] = useState(""); //current member name
  const [duplicateNameError, setDuplicateNameError] = useState<string>("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { groups, selectedGroupIndex } = useSelector(
    (state: { group: GroupState }) => state.group
  );
  const { events, selectedEventIndex } = useSelector(
    (state: { event: EventState }) => state.event
  );
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const saveEditTeam = (name: string, index: number) => {
    name = name.trim();
    setEditTeam(name, index);
    setEditShow(false);
  };

  const onMsgModalClose = () => {};

  const allowSave = () => {
    return !!editTeamValue.trim().length && duplicateNameError == "";
  };

  const checkTeamExists = (
    name: string,
    groupIndex: number,
    selectedTeamName: string
  ) =>
    !!groups[groupIndex].members.find(
      (member: any) => member.name !== selectedTeamName && member.name === name
    );

  const validate = (name: string) => {
    name = name.trim();
    if (checkTeamExists(name, selectedGroupIndex, selectedTeamName)) {
      setDuplicateNameError(name);
    } else {
      setDuplicateNameError("");
    }
  };

  const isTeamCountMin = () => {
    const activeTeamsCount = events[selectedEventIndex].teams.filter(
      (team: any) => team.selected
    ).length;
    return activeTeamsCount <= MIN_ACTIVE_TEAMS_COUNT;
  };

  useEffect(() => {
    if (editShow) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [editShow]);

  return (
    <>
      <div className="members-list-item">
        {name}
        {edit && (
          <span className="ms-auto me-4">
            <span
              className="badge badge-secondary badge-pill"
              onClick={() => {
                setEditShow(true);
                setEditTeamValue(name);
                setSelectedTeamName(name);
              }}
            >
              Edit
            </span>
            <span
              className="badge badge-danger badge-pill"
              style={{ marginLeft: 3 }}
              onClick={() => {
                if (isTeamCountMin() && selected) {
                  setModalMessage(TEAMS_COUNT_LOW);
                  setShowMessageModal(true);
                } else {
                  setDeleteShow(true);
                  setDeleteTeamValue(name);
                }
              }}
            >
              Delete
            </span>
          </span>
        )}
        <Switch
          onChange={(checked) => {
            handleChange(checked, index);
          }}
          checked={selected}
        ></Switch>
      </div>
      <Modal
        show={editShow}
        backdrop="static"
        onHide={() => {
          setEditShow(false);
        }}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>{EDIT_TEAM_NAME}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={editTeamValue}
              maxLength={MAX_NAME_LENGTH}
              onKeyUp={(e) => {
                if (e.key === "Enter" && allowSave()) {
                  saveEditTeam(editTeamValue, index);
                }
              }}
              onChange={(e) => {
                setEditTeamValue(e.target.value);
                validate(e.target.value);
              }}
              placeholder="Team name"
              ref={inputRef}
            />
            {duplicateNameError && (
              <span className="error">{`Team with name ${duplicateNameError} already exists`}</span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setEditShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!allowSave()}
            onClick={() => {
              saveEditTeam(editTeamValue, index);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deleteShow}
        backdrop="static"
        onHide={() => {
          setDeleteShow(false);
        }}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>{DELETE_TEAM}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure to delete member <b>{deleteTeamValue}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setDeleteTeam(index);
              setDeleteShow(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        showHideModal={setShowMessageModal}
        onClose={onMsgModalClose}
      ></MessageModal>
    </>
  );
};

export default Team;
