import { eventsList } from "../../utils/constants";
import {
  SetEvents,
  SetSelectedEventIndex,
  SET_EVENTS,
  SET_SELECTED_EVENT_INDEX,
} from "../types/event";

const initialState = {
  events: [...eventsList],
  selectedEventIndex: 0,
};

const eventReducer = (
  state = initialState,
  action: SetEvents | SetSelectedEventIndex
) => {
  switch (action.type) {
    case SET_EVENTS: {
      return { ...state, events: action.payload };
    }
    case SET_SELECTED_EVENT_INDEX: {
      return { ...state, selectedEventIndex: action.payload };
    }
    default:
      return state;
  }
};

export default eventReducer;
