export const themesList = [
  // { Magic theme removed
  //   id: 0,
  //   name: "theme-0",
  // },
  {
    id: 1,
    name: "theme-1",
  },
  {
    id: 2,
    name: "theme-2",
  },
  // { Lab theme removed
  //   id: 3,
  //   name: "theme-3",
  // },
  {
    id: 4,
    name: "theme-4",
  },
  {
    id: 5,
    name: "theme-5",
  },
  {
    id: 6,
    name: "theme-6",
  },
];
export const PLAY = "PLAY";
export const STOP = "STOP";

export const groupsList = [
  {
    name: "Ms Jones Math Class",
    maxShowCount: 1,
    members: [
      { name: "Peter", selected: true, displayed: 0 },
      { name: "Mark", selected: true, displayed: 0 },
      { name: "James", selected: true, displayed: 0 },
      { name: "Simon", selected: true, displayed: 0 },
      { name: "Paul", selected: true, displayed: 0 },
      { name: "Jamie", selected: true, displayed: 0 },
      { name: "Umair", selected: true, displayed: 0 },
      { name: "Luis", selected: true, displayed: 0 },
      { name: "Varinder", selected: true, displayed: 0 },
      { name: "Robert", selected: true, displayed: 0 },
      { name: "Sally", selected: true, displayed: 0 },
      { name: "Claire", selected: true, displayed: 0 },
      { name: "Hope", selected: true, displayed: 0 },
      { name: "Eden", selected: true, displayed: 0 },
      { name: "Jon", selected: true, displayed: 0 },
      { name: "Ben", selected: true, displayed: 0 },
    ],
  },
  {
    name: "Spelling Bee Contestants",
    maxShowCount: 1,
    members: [
      { name: "Marc", selected: true, displayed: 0 },
      { name: "Benjamin", selected: true, displayed: 0 },
      { name: "Norman", selected: true, displayed: 0 },
      { name: "Amelia", selected: true, displayed: 0 },
      { name: "Ravinder", selected: true, displayed: 0 },
      { name: "Sally", selected: true, displayed: 0 },
      { name: "James", selected: true, displayed: 0 },
      { name: "Zoe", selected: true, displayed: 0 },
      { name: "Veronica", selected: true, displayed: 0 },
      { name: "Lorna", selected: true, displayed: 0 },
      { name: "Kent", selected: true, displayed: 0 },
      { name: "Brian", selected: true, displayed: 0 },
    ],
  },
  {
    name: "Ms Smiths Class",
    maxShowCount: 1,
    members: [
      { name: "Kirk", selected: true, displayed: 0 },
      { name: "Tom", selected: true, displayed: 0 },
      { name: "Andrew", selected: true, displayed: 0 },
      { name: "Simon", selected: true, displayed: 0 },
      { name: "Susan", selected: true, displayed: 0 },
      { name: "Sally", selected: true, displayed: 0 },
      { name: "Victoria", selected: true, displayed: 0 },
      { name: "Naomi", selected: true, displayed: 0 },
      { name: "Niky", selected: true, displayed: 0 },
      { name: "Christopher", selected: true, displayed: 0 },
      { name: "Steven", selected: true, displayed: 0 },
      { name: "Umair", selected: true, displayed: 0 },
      { name: "Varinder", selected: true, displayed: 0 },
      { name: "Luis", selected: true, displayed: 0 },
    ],
  },
  {
    name: "Year 7",
    maxShowCount: 1,
    members: [
      { name: "Andrew", selected: true, displayed: 0 },
      { name: "Brian", selected: true, displayed: 0 },
      { name: "Ben", selected: true, displayed: 0 },
      { name: "Berlinda", selected: true, displayed: 0 },
      { name: "Zoe", selected: true, displayed: 0 },
      { name: "Zac", selected: true, displayed: 0 },
      { name: "Ziverdean", selected: true, displayed: 0 },
      { name: "Kirk", selected: true, displayed: 0 },
      { name: "Kieran", selected: true, displayed: 0 },
      { name: "James", selected: true, displayed: 0 },
      { name: "Jonathan", selected: true, displayed: 0 },
      { name: "John", selected: true, displayed: 0 },
      { name: "Jamie", selected: true, displayed: 0 },
      { name: "Jackie", selected: true, displayed: 0 },
      { name: "Stephen", selected: true, displayed: 0 },
      { name: "Lorna", selected: true, displayed: 0 },
      { name: "Wendy", selected: true, displayed: 0 },
      { name: "William", selected: true, displayed: 0 },
      { name: "Billy", selected: true, displayed: 0 },
      { name: "Liam", selected: true, displayed: 0 },
      { name: "Olivia", selected: true, displayed: 0 },
      { name: "Noah", selected: true, displayed: 0 },
      { name: "Emma", selected: true, displayed: 0 },
      { name: "Oliver", selected: true, displayed: 0 },
      { name: "Charlotte", selected: true, displayed: 0 },
      { name: "Elijah", selected: true, displayed: 0 },
      { name: "Amelia", selected: true, displayed: 0 },
      { name: "Ava", selected: true, displayed: 0 },
      { name: "Sophia", selected: true, displayed: 0 },
      { name: "Benjamin", selected: true, displayed: 0 },
      { name: "Isabella", selected: true, displayed: 0 },
      { name: "Lucas", selected: true, displayed: 0 },
      { name: "Mia", selected: true, displayed: 0 },
      { name: "Henry", selected: true, displayed: 0 },
      { name: "Evelyn", selected: true, displayed: 0 },
      { name: "Theodore", selected: true, displayed: 0 },
      { name: "Harper", selected: true, displayed: 0 },
      { name: "Jenny", selected: true, displayed: 0 },
    ],
  },
];
export const eventsList = [
  {
    name: "Track and Field Event",
    teams: [
      { name: "Amigos", selected: true },
      { name: "Olympians", selected: true },
      { name: "Cougars", selected: true },
      { name: "Colonels", selected: true },
    ],
  },
  {
    name: "Softball Event",
    teams: [
      { name: "Yankees", selected: true },
      { name: "Astros", selected: true },
      { name: "Tigers", selected: true },
      { name: "Indians", selected: true },
      { name: "Orioles", selected: true },
      { name: "Twins", selected: true },
      { name: "Pirates", selected: true },
      { name: "Mariners", selected: true },
    ],
  },
  {
    name: "History Quiz",
    teams: [
      { name: "Team Red", selected: true },
      { name: "Team Blue", selected: true },
      { name: "Team Green", selected: true },
      { name: "Team Purple", selected: true },
      { name: "Team White", selected: true },
      { name: "Team Orange", selected: true },
      { name: "Team Yellow", selected: true },
    ],
  },
  {
    name: "Spelling Bee Event",
    teams: [
      { name: "Master Minds", selected: true },
      { name: "Team Wisdom", selected: true },
      { name: "Shining Stars", selected: true },
      { name: "Spell Casters", selected: true },
      { name: "Better Bees", selected: true },
      { name: "Beelieve", selected: true },
      { name: "The Bees Knees", selected: true },
      { name: "The Spellicans", selected: true },
    ],
  },
];
export const numberToWord = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];

export const GROUP_NAME: string = "Group Name";
export const EVENT_NAME: string = "Event Name";
export const GROUP_MEMBER: string = "Group Member";
export const EVENT_TEAM: string = "Add Team";
export const MAX_NAME_LENGTH: number = 40;
export const MIN_ACTIVE_MEMBERS_COUNT: number = 2;
export const MAX_ACTIVE_MEMBERS_COUNT: number = 42;
export const MAX_ACTIVE_TEAMS_COUNT: number = 8;
export const MIN_ACTIVE_TEAMS_COUNT: number = 2;
export const MEMBERS_COUNT_LOW: string = `Active members count can not be less than ${MIN_ACTIVE_MEMBERS_COUNT}`;
export const MEMBERS_COUNT_EXCEEDED: string = `Active members count can not exceed ${MAX_ACTIVE_MEMBERS_COUNT}`;
export const TEAMS_COUNT_LOW: string = `Active teams count can not be less than ${MIN_ACTIVE_TEAMS_COUNT}`;
export const TEAMS_COUNT_EXCEEDED: string = `Active teams count can not exceed ${MAX_ACTIVE_TEAMS_COUNT}`;
export const TEAMS_ADDED_AND_COUNT_EXCEEDED: string = `Only ${MAX_ACTIVE_TEAMS_COUNT} teams can be active at once. Others will be added in inactive state`;
export const MEMBERS_ADDED_AND_COUNT_EXCEEDED: string = `Only ${MAX_ACTIVE_MEMBERS_COUNT} members can be added`;
export const SETTINGS_LIST = "Settings List";
export const THEMES_LIST = "Themes List";
export const EXPORT_FILE = "Export File";
export const EXPORT_FILE_LOAD = "Export File Load";
export const RESTORE_DATA = "Restore Data";
export const BACKUP_DATA = "Backup Data";
export const EXPORT_IMPORT_FILE_DEFAULT_NAME = "data.csv";
export const EXPORT_IMPORT_FILE_DEFAULT_NAME_GROUPS = "Groups-GroupMembers.csv";
export const EXPORT_IMPORT_FILE_DEFAULT_NAME_EVENTS = "Events-Teams.csv";
export const MIN_SCALE = {
  width: 768,
  height: 432,
}; //width/height below which app won't scale

/*Overlay labels*/
export const EXIT_APPLICATION = "Exit Application";
export const HELP = "Access this context sensitive help";
export const FAQ_LABEL = "Access our frequently asked questions";
export const FULL_SCREEN_TOGGLE = "Toggle full screen mode";
export const RESET = "Restart name drawing from scratch";
export const SOUND_TOGGLE = "Toggle sound effects on or off";
export const MUSIC_TOGGLE = "Toggle background music on or off";
export const EDIT_GROUPS = "Edit the members and groups";
export const EDIT_EVENTS = "Edit the events and teams";
export const SETTINGS = "Access this app settings";

/*File errors*/
export const LINE_MALFORMED = "Line is malformed";
export const MEMBER_MISSING = "Member is missing";
export const GROUP_MISSING = "Group is missing";
export const LINE_EMPTY = "Line is empty";
export const MEMBER_DUPLICATE = "Member is duplicate";

/*URLS*/
export const WEBSITE_URL = "https://www.edtechmonster.com";
export const MOBILE_APP_URL = "https://www.edtechmonster.com/mobileapps";
export const FAQ_URL: string =
  "https://www.edtechmonster.com/random-team-selector-pro-webapp-support";
export const LIBRARY_URL: string = "https://www.edtechmonster.com/library";

/* popup messages*/
export const ALL_MEMBERS_DISPLAYED =
  "All members have been displayed at least once";
export const UNEQUAL_MEMBERS =
  "Note: Teams will have an unequal number of members";
export const SELECT_GROUP_WITH_ATLEAST_ONE_MEMBER =
  "Please select group with at least one enabled member";
export const GROUPS_MERGED = "Groups merged successfully";
export const EVENTS_MERGED = "Events merged successfully";
export const GROUPS_REPLACED = "File Groups replaced successfully";
export const EVENTS_REPLACED = "File Events replaced successfully";
export const FILE_IMPORT_FAILED = "File not imported";
export const FILE_IMPORT_SUCCESS = "File has been imported successfully";
export const FILE_IMPORT_PARTIAL_SUCCESS =
  "File has been imported with one or more errors";
export const GROUP_NAMES_RESET =
  "Names for this Group have been successfully reset";
export const FILENAME_EMPTY_ERROR = " File name can not be empty";
export const FOLDER_NAME_EMPTY_ERROR = "Folder name can not be empty";
export const RNS_MODAL_GROUP_NAME_BODY_TEXT =
  "If entering multiple Group Names please separate with a , (comma) e.g. Ms Jones Class, Math Class, Spelling Bee Contestants. The maximum number of characters per group name is";
export const RNS_MODAL_GROUP_MEMBERS_BODY_TEXT =
  "If entering multiple names please separate with a , (comma) e.g Peter, Umair, Luis. Max number of characters per name is";
export const RNS_MODAL_EVENT_NAME_BODY_TEXT =
  "If entering multiple Event Names please separate with a , (comma) e.g. Ms Jones Class, Math Class, Spelling Bee Contestants. The maximum number of characters per group name is";
export const RNS_MODAL_EVENT_TEAMS_BODY_TEXT =
  "If entering multiple Team Names please separate with a , (comma) e.g. Ms Jones Class, Math Class, Spelling Bee Contestants. The maximum number of characters per group name is";
export const EDIT_GROUP_NAME = "Edit Group Name";
export const EDIT_EVENT_NAME = "Edit Event Name";
export const EDIT_MEMBER_NAME = "Edit Member Name";
export const EDIT_TEAM_NAME = "Edit Team Name";
export const DELETE_MEMBER = "Delete Member";
export const DELETE_TEAM = "Delete Team";
export const DELETE_GROUP = "Delete Group";
export const DELETE_EVENT = "Delete Event";
export const FILE_ALREADY_EXISTS = "File with this name already exists";
