import { combineReducers } from "redux";
import group from "./group";
import event from "./event";
import common from "./common";

const rootReducer = combineReducers({
  event,
  group,
  common,
});

export default rootReducer;
