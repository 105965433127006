import React, { useEffect, useRef, useState } from "react";
import "../App.scss";
import { Button, Modal } from "react-bootstrap";
import { GroupState } from "../store/types/group";
import { useSelector } from "react-redux";
import {
  EVENT_NAME,
  EVENT_TEAM,
  GROUP_MEMBER,
  GROUP_NAME,
  MAX_NAME_LENGTH,
} from "../utils/constants";
import { EventState } from "../store/types/event";
type ModalProps = {
  show: boolean;
  title: string;
  body: string;
  showHideModal?: (flag: boolean) => void;
  addName?: (name: string) => void;
};
const RNSModal: React.FC<ModalProps> = ({
  show,
  title,
  body,
  showHideModal = (flag: boolean) => {},
  addName = (name: string) => {},
}) => {
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [name, setName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [duplicateErrMsg, setDuplicateErrMsg] = useState("");
  const { groups, selectedGroupIndex } = useSelector(
    (state: { group: GroupState }) => state.group
  );
  const { events, selectedEventIndex } = useSelector(
    (state: { event: EventState }) => state.event
  );

  const checkMemberExists = (name: string, groupIndex: number) =>
    !!groups[groupIndex].members.find((member: any) => member.name == name);

  const checkTeamExists = (name: string, eventIndex: number) =>
    !!events[eventIndex].teams.find((team: any) => team.name == name);

  const checkGroupExists = (name: string) =>
    !!groups.find((group: any) => group.name == name);

  const checkEventExists = (name: string) =>
    !!events.find((event: any) => event.name == name);

  let findDuplicatesInArr = (arr: Array<string>) => {
    let duplicates: any = {};
    arr.forEach((name, index) => {
      if (arr.indexOf(name) != index) {
        duplicates[name] = name;
      }
    });
    return Object.keys(duplicates);
  };

  const checkDuplicateName = (name: string) => {
    let errorName: Array<string> = [];
    let namesArr: Array<string> = name.split(",");
    const duplicateValues = findDuplicatesInArr(namesArr);
    if (duplicateValues.length > 0) {
      errorName = [...errorName, ...duplicateValues];
    }
    namesArr.forEach((name: string) => {
      name = name.trim();
      if (title == GROUP_MEMBER) {
        if (checkMemberExists(name, selectedGroupIndex)) {
          errorName.push(name);
        }
      } else if (title == GROUP_NAME) {
        if (checkGroupExists(name)) {
          errorName.push(name);
        }
      } else if (title == EVENT_TEAM) {
        if (checkTeamExists(name, selectedEventIndex)) {
          errorName.push(name);
        }
      } else if (title == EVENT_NAME) {
        if (checkEventExists(name)) {
          errorName.push(name);
        }
      }
    });
    return errorName;
  };

  const validate = (name: string) => {
    let errorName: Array<string> = [];
    let namesArr: Array<string> = name.split(",");
    namesArr.forEach((name: string) => {
      if (name.length > MAX_NAME_LENGTH) {
        errorName.push(name);
      }
    });
    return errorName;
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      backdrop="static"
      dialogClassName="rns-modal"
      onHide={() => {
        showHideModal(false);
      }}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title className="w-100 text-center">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>{body}</p>
        <input
          className="form-control form-control-lg"
          type="text"
          placeholder=""
          value={name}
          onKeyUp={(e) => {
            const errNamesArray: Array<string> = validate(name);
            const duplicateError: Array<string> = checkDuplicateName(name);
            if (duplicateError.length) {
              setDuplicateErrMsg(
                `Duplicate name${
                  duplicateError.length > 1 ? "s" : ""
                } ${duplicateError.join()}`
              );
            } else {
              setDuplicateErrMsg("");
            }
            if (errNamesArray.length) {
              setErrMsg(
                `${title}${
                  errNamesArray.length > 1 ? "s" : ""
                } "${errNamesArray.join()}" ${
                  errNamesArray.length > 1 ? "have" : "has"
                } more than ${MAX_NAME_LENGTH} characters`
              );
            } else {
              setErrMsg("");
            }
            if (
              name.trim() !== "" &&
              errMsg === "" &&
              duplicateErrMsg === "" &&
              e.key === "Enter"
            ) {
              addName(name);
              showHideModal(false);
              setName("");
            }
          }}
          onChange={(e) => {
            setName(e.target.value);
          }}
          ref={inputRef}
        ></input>
        <div style={{ wordBreak: "break-word" }}>{errMsg}</div>
        <div>{duplicateErrMsg}</div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center custom-footer">
        <button
          className="btn-custom"
          onClick={() => {
            showHideModal(false);
            setName("");
            setDuplicateErrMsg("");
            setErrMsg("");
          }}
        >
          Cancel
        </button>
        <button
          className="btn-custom"
          onClick={() => {
            addName(name);
            showHideModal(false);
            setName("");
            setDuplicateErrMsg("");
            setErrMsg("");
          }}
          disabled={
            !name.trim().length || errMsg !== "" || duplicateErrMsg !== ""
          }
        >
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RNSModal;
