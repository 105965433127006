import React, { useEffect, useRef, useState } from "react";
import "../App.scss";
import Switch from "../components/Switch";
import { Button, Modal } from "react-bootstrap";
import {
  DELETE_MEMBER,
  EDIT_MEMBER_NAME,
  MAX_NAME_LENGTH,
  MEMBERS_COUNT_LOW,
  MIN_ACTIVE_MEMBERS_COUNT,
} from "../utils/constants";
import { GroupState } from "../store/types/group";
import { useSelector } from "react-redux";
import MessageModal from "./MessageModal";
type MemberProps = {
  name: string;
  edit: boolean;
  selected: boolean;
  index: number;
  handleChange?: (checked: boolean, index: number) => void;
  setEditMember?: (memberName: string, index: number) => void;
  setDeleteMember?: (index: number) => void;
};
const Member: React.FC<MemberProps> = ({
  name,
  edit,
  selected,
  index,
  handleChange = (checked: boolean, index: number) => {},
  setEditMember = (memberName: string, index: number) => {},
  setDeleteMember = (index: number) => {},
}) => {
  const [editShow, setEditShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [editMemberValue, setEditMemberValue] = useState("");
  const [deleteMemberValue, setDeleteMemberValue] = useState("");
  const [selectedMemberName, setSelectedMemberName] = useState(""); //current member name
  const [duplicateNameError, setDuplicateNameError] = useState<string>("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { groups, selectedGroupIndex } = useSelector(
    (state: { group: GroupState }) => state.group
  );
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const saveEditMember = (name: string, index: number) => {
    name = name.trim();
    setEditMember(name, index);
    setEditShow(false);
  };

  const onMsgModalClose = () => {};

  const allowSave = () => {
    return !!editMemberValue.trim().length && duplicateNameError == "";
  };

  const checkMemberExists = (
    name: string,
    groupIndex: number,
    selectedMemberName: string
  ) =>
    !!groups[groupIndex].members.find(
      (member: any) =>
        member.name !== selectedMemberName && member.name === name
    );

  const validate = (name: string) => {
    name = name.trim();
    if (checkMemberExists(name, selectedGroupIndex, selectedMemberName)) {
      setDuplicateNameError(name);
    } else {
      setDuplicateNameError("");
    }
  };

  const isMemberCountMin = () => {
    const activeMembersCount = groups[selectedGroupIndex].members.filter(
      (member: any) => member.selected
    ).length;
    return activeMembersCount <= MIN_ACTIVE_MEMBERS_COUNT;
  };

  useEffect(() => {
    if (editShow) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [editShow]);

  return (
    <>
      <div className="members-list-item">
        {name}
        {edit && (
          <span className="ms-auto me-4">
            <span
              className="badge badge-secondary badge-pill"
              onClick={() => {
                setEditShow(true);
                setEditMemberValue(name);
                setSelectedMemberName(name);
              }}
            >
              Edit
            </span>
            <span
              className="badge badge-danger badge-pill"
              style={{ marginLeft: 3 }}
              onClick={() => {
                if (isMemberCountMin() && selected) {
                  setModalMessage(MEMBERS_COUNT_LOW);
                  setShowMessageModal(true);
                } else {
                  setDeleteShow(true);
                  setDeleteMemberValue(name);
                }
              }}
            >
              Delete
            </span>
          </span>
        )}
        <Switch
          onChange={(checked) => {
            handleChange(checked, index);
          }}
          checked={selected}
        ></Switch>
      </div>
      <Modal
        show={editShow}
        onHide={() => {
          setEditShow(false);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>{EDIT_MEMBER_NAME}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={editMemberValue}
              maxLength={MAX_NAME_LENGTH}
              onKeyUp={(e) => {
                if (e.key === "Enter" && allowSave()) {
                  saveEditMember(editMemberValue, index);
                }
              }}
              onChange={(e) => {
                setEditMemberValue(e.target.value);
                validate(e.target.value);
              }}
              placeholder="Member name"
              ref={inputRef}
            />
            {duplicateNameError && (
              <span className="error">{`Member with name ${duplicateNameError} already exists`}</span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setEditShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!allowSave()}
            onClick={() => {
              saveEditMember(editMemberValue, index);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={deleteShow}
        backdrop="static"
        onHide={() => {
          setDeleteShow(false);
        }}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>{DELETE_MEMBER}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure to delete member <b>{deleteMemberValue}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setDeleteMember(index);
              setDeleteShow(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        showHideModal={setShowMessageModal}
        onClose={onMsgModalClose}
      ></MessageModal>
    </>
  );
};

export default Member;
