export const SET_EVENTS = "SET_EVENTS";
export const SET_SELECTED_EVENT_INDEX = "SET_SELECTED_EVENT_INDEX";

export type EventState = {
  events: any;
  selectedEventIndex: number;
};

export type SetEvents = {
  type: typeof SET_EVENTS;
  payload: any[];
};

export type SetSelectedEventIndex = {
  type: typeof SET_SELECTED_EVENT_INDEX;
  payload: number;
};
